/**
 * PlantApi
 * 1.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.Defaults<Oazapfts.CustomHeaders> = {
    headers: {},
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type DepartmentDto = {
    "number": number;
    name: string;
    "default": boolean;
    active: boolean;
    comments: string;
};
export type DataStatus = "success" | "notValid" | "exception" | "error" | "inUse" | "notFound" | "noChange" | "inProgress" | "invalidPage" | "duplicateEntity" | "noContent";
export type ErrorResponse = {
    message: string;
    kind: DataStatus;
    exception: string | null;
    stackTrace: string | null;
    exceptionType: string;
    innerException: string | null;
    validationErrors: {
        [key: string]: string[];
    } | null;
};
export type EmployeeDto = {
    "number": number;
    firstName: string;
    middleInitial: string;
    lastName: string;
    address1: string;
    address2: string;
    address3: string;
    phone1: string;
    phone2: string;
    hired?: string | null;
    terminated?: string | null;
    payRate: number;
    payPeriod: string;
    comment: string;
    jobClass: string;
    supervisor: boolean;
    department: number;
    team: boolean;
    supervisorNumber: number;
    shift: number;
    emailAddress: string;
    smsAddress: string;
};
export type EmployeeDtoListResultSet = {
    totalResults: number;
    totalPages: number;
    currentPage: number;
    pageSize: number;
    previousUrl: string | null;
    nextUrl: string | null;
    filter: any | null;
    data: EmployeeDto[];
};
export type ValidationProblemDetails = {
    "type": string | null;
    title: string | null;
    status?: number | null;
    detail: string | null;
    instance: string | null;
    errors: {
        [key: string]: string[];
    };
    [key: string]: any;
};
export type EmployeeLookUpViewDto = {
    "number": number;
    name: string;
    supervisor: boolean;
    department: number;
    shift: number;
    skills: string[];
};
export type EmployeeShiftListDto = {
    shift: number;
    employeeShiftList: number[];
};
export type EmployeeShiftResponse = {
    employee: number;
    message: string;
};
export type EmployeeShiftResponseListPostDto = {
    successData: EmployeeShiftResponse[] | null;
    failureData: EmployeeShiftResponse[] | null;
};
export type EmployeeBulkTrainingDto = {
    trainingCode: string;
    trainerId: number;
    beginTrainDate?: string | null;
    endTrainDate?: string | null;
    certificationStartDate?: string | null;
    employeeIdList: number[];
};
export type EmployeeBulkTrainingDtoPostDto = {
    successData: EmployeeBulkTrainingDto;
    failureData: EmployeeBulkTrainingDto;
};
export type DefaultAssignmentDto = {
    equipmentNumber: number;
    employeeNumber: number;
    skillTypeCode: string;
};
export type EmpTrainDto = {
    employeeNumber: number;
    trainingCode: string;
    beginTrainDate?: string | null;
    endTrainDate?: string | null;
    isCertified: boolean;
    isAttendanceOnly: boolean;
    isPassFailScore: boolean;
    passingScore: number;
    maxScore: number;
    employeeScore: number;
    employeePassFailScore: boolean;
    certificationLength?: number | null;
    certificationStartDate?: string | null;
    certificationEndDate?: string | null;
    trainerId: number;
};
export type EmpSkillViewDto = {
    employeeNumber: number;
    skillCode: string;
    supervisorId?: number | null;
    isCertified: boolean;
    beginTrainDate?: string | null;
    endTrainDate?: string | null;
    certificationLength: number;
    certificationStartDate?: string | null;
    certificationEndDate?: string | null;
    trainData: EmpTrainDto[];
};
export type EmpSkillDto = {
    employeeNumber: number;
    skillCode: string;
    supervisorId: number;
    isCertified: boolean;
    beginTrainDate?: string | null;
    endTrainDate?: string | null;
    certificationLength: number;
    certificationStartDate?: string | null;
    certificationEndDate?: string | null;
};
export type SkillTypeDto = {
    code: string;
    name: string;
    active: boolean;
    certificationLength: number;
    isDefault: boolean;
    trainings: string[];
};
export type TeamDto = {
    "number": string;
    name: string;
    supervisor: number;
    active: boolean;
};
export type TeamDocumentDto = {
    team: TeamDto;
    employees: number[];
};
export type TrainTypeDto = {
    code: string;
    name: string;
    active: boolean;
    isScored: boolean;
    isPassFail: boolean;
    isAttendanceOnly: boolean;
    passingScore: string;
    maxScore: string;
    certificationLength?: number | null;
};
export function getDepartmentList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DepartmentDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/department", {
        ...opts
    });
}
export function createDepartment(departmentDto: DepartmentDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DepartmentDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/department", oazapfts.json({
        ...opts,
        method: "POST",
        body: departmentDto
    }));
}
export function getDepartment($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DepartmentDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/department/${encodeURIComponent($number)}`, {
        ...opts
    });
}
export function updateDepartment($number: number, departmentDto: DepartmentDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DepartmentDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/department/${encodeURIComponent($number)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: departmentDto
    }));
}
export function deleteDepartment($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/department/${encodeURIComponent($number)}`, {
        ...opts,
        method: "DELETE"
    });
}
export function getEmployeeList({ page, pageSize, department, supervisorOnly, name }: {
    page?: number;
    pageSize?: number;
    department?: number;
    supervisorOnly?: boolean;
    name?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EmployeeDtoListResultSet;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/employee${QS.query(QS.explode({
        page,
        pageSize,
        department,
        supervisorOnly,
        name
    }))}`, {
        ...opts
    });
}
/**
 * Employee Number is provided by the End User. It is not auto generated. 409 error on attempted duplicate.
 */
export function createEmployeeList(employeeDto: EmployeeDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EmployeeDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/employee", oazapfts.json({
        ...opts,
        method: "POST",
        body: employeeDto
    }));
}
export function getEmployeeLookupList({ department, shift, supervisorOnly, activeOnly }: {
    department?: string;
    shift?: string;
    supervisorOnly?: boolean;
    activeOnly?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EmployeeLookUpViewDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/employee/lookuplist${QS.query(QS.explode({
        department,
        shift,
        supervisorOnly,
        activeOnly
    }))}`, {
        ...opts
    });
}
export function getEmployee($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EmployeeDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/employee/${encodeURIComponent($number)}`, {
        ...opts
    });
}
export function updateEmployee($number: number, employeeDto: EmployeeDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EmployeeDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/employee/${encodeURIComponent($number)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: employeeDto
    }));
}
export function deleteEmployee($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/employee/${encodeURIComponent($number)}`, {
        ...opts,
        method: "DELETE"
    });
}
export function createEmployeeBulkShift(employeeShiftListDto: EmployeeShiftListDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EmployeeShiftResponseListPostDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/employee/bulkshift", oazapfts.json({
        ...opts,
        method: "POST",
        body: employeeShiftListDto
    }));
}
export function createEmployeeBulkTrain(employeeBulkTrainingDto: EmployeeBulkTrainingDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EmployeeBulkTrainingDtoPostDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/employee/bulktraining", oazapfts.json({
        ...opts,
        method: "POST",
        body: employeeBulkTrainingDto
    }));
}
/**
 * Default Assignments for this Employee
 */
export function getEmployeeDefaultAssignmentList($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DefaultAssignmentDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/employee/${encodeURIComponent($number)}/defaultassignments`, {
        ...opts
    });
}
export function getEmployeeSkillList($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EmpSkillViewDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/employee/${encodeURIComponent($number)}/skill`, {
        ...opts
    });
}
export function getEmployeeSkill($number: number, code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EmpSkillDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/employee/${encodeURIComponent($number)}/skill/${encodeURIComponent(code)}`, {
        ...opts
    });
}
export function updateEmployeeSkill($number: number, code: string, empSkillDto: EmpSkillDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EmpSkillDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/employee/${encodeURIComponent($number)}/skill/${encodeURIComponent(code)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: empSkillDto
    }));
}
export function deleteEmployeeSkill($number: number, code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/employee/${encodeURIComponent($number)}/skill/${encodeURIComponent(code)}`, {
        ...opts,
        method: "DELETE"
    });
}
export function createEmployeeSkill(empSkillDto: EmpSkillDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EmpSkillDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/employee/skill", oazapfts.json({
        ...opts,
        method: "POST",
        body: empSkillDto
    }));
}
export function getEmployeeTrainingList($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EmpTrainDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/employee/${encodeURIComponent($number)}/training`, {
        ...opts
    });
}
export function createEmployeeTraining($number: number, empTrainDto: EmpTrainDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EmpTrainDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/employee/${encodeURIComponent($number)}/training`, oazapfts.json({
        ...opts,
        method: "POST",
        body: empTrainDto
    }));
}
export function getEmployeeTraining($number: number, code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EmpTrainDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/employee/${encodeURIComponent($number)}/training/${encodeURIComponent(code)}`, {
        ...opts
    });
}
export function updateEmployeeTraining($number: number, code: string, empTrainDto: EmpTrainDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: EmpTrainDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/employee/${encodeURIComponent($number)}/training/${encodeURIComponent(code)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: empTrainDto
    }));
}
export function deleteEmployeeTraining($number: number, code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/employee/${encodeURIComponent($number)}/training/${encodeURIComponent(code)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Update or Create Default Worksheet Assignment - Must include valid Employee, Equipment/Area, and SkillType Code.
 */
export function updateEquipmentDefaultAssignment(employeeNumber: number, code: string, equipmentNumber: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DefaultAssignmentDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/equipment/${encodeURIComponent(equipmentNumber)}/skill/${encodeURIComponent(code)}/employee/${encodeURIComponent(employeeNumber)}`, {
        ...opts,
        method: "PUT"
    });
}
export function getSkillList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SkillTypeDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/skill", {
        ...opts
    });
}
/**
 * Skill Type Code is provided by the End User. It is not auto generated. 409 error on attempted duplicate.
 */
export function createSkill(skillTypeDto: SkillTypeDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SkillTypeDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/skill", oazapfts.json({
        ...opts,
        method: "POST",
        body: skillTypeDto
    }));
}
export function getSkill(code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SkillTypeDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/skill/${encodeURIComponent(code)}`, {
        ...opts
    });
}
export function updateSkill(code: string, skillTypeDto: SkillTypeDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SkillTypeDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/skill/${encodeURIComponent(code)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: skillTypeDto
    }));
}
export function deleteSkill(code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/skill/${encodeURIComponent(code)}`, {
        ...opts,
        method: "DELETE"
    });
}
export function getTeamList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TeamDocumentDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/teams", {
        ...opts
    });
}
export function createTeam(teamDocumentDto: TeamDocumentDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TeamDocumentDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/teams", oazapfts.json({
        ...opts,
        method: "POST",
        body: teamDocumentDto
    }));
}
export function getTeam(code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TeamDocumentDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/teams/${encodeURIComponent(code)}`, {
        ...opts
    });
}
export function updateTeam(code: string, teamDocumentDto: TeamDocumentDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TeamDocumentDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/teams/${encodeURIComponent(code)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: teamDocumentDto
    }));
}
export function deleteTeam(code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/teams/${encodeURIComponent(code)}`, {
        ...opts,
        method: "DELETE"
    });
}
export function getTrainingTypeList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TrainTypeDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/training", {
        ...opts
    });
}
/**
 * Training Type Code is provided by the End User. It is not auto generated. 409 error on attempted duplicate.
 */
export function createTrainingType(trainTypeDto: TrainTypeDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TrainTypeDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/training", oazapfts.json({
        ...opts,
        method: "POST",
        body: trainTypeDto
    }));
}
export function getTrainingType(code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TrainTypeDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/training/${encodeURIComponent(code)}`, {
        ...opts
    });
}
export function updateTrainingType(code: string, trainTypeDto: TrainTypeDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TrainTypeDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/training/${encodeURIComponent(code)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: trainTypeDto
    }));
}
export function deleteTrainingType(code: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/training/${encodeURIComponent(code)}`, {
        ...opts,
        method: "DELETE"
    });
}
