import { useLocalFetch } from "~/composables/apiHandler.ts";
import { defineStore } from "pinia";
import { getCompanyConnectStatus } from "~/connectables/fetch/companyInformation.ts";
import { TimeInMS } from "#imports";

export const useConnectStatusStore = defineStore("connectStatus", () => {
  const {
    fetch: originalFetch,
    reset: originalReset,
    ...theRest
  } = useLocalFetch("getCompanyConnectStatus", getCompanyConnectStatus);

  let interval: number | null | NodeJS.Timeout = null;
  const fetch = () => {
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(originalFetch, TimeInMS.FIVE_MINUTES);
    return originalFetch();
  };

  const reset = () => {
    if (interval) {
      clearInterval(interval);
    }
    return originalReset();
  };

  return { fetch, reset, ...theRest };
});
