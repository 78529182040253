import type { Breadcrumb, CaptureContext, Primitive, User } from "@sentry/types";
import type * as Sentry from "@sentry/vue";
import {
  replayIntegration,
  browserTracingIntegration,
  feedbackIntegration,
  httpClientIntegration,
  reportingObserverIntegration,
  init,
  showReportDialog,
  setContext,
  setUser,
  setTag,
  addBreadcrumb,
  captureException,
} from "@sentry/vue";
import type { Router } from "vue-router";
import { defineNuxtPlugin } from "#app";

declare module "#app" {
  interface NuxtApp {
    $sentrySetContext: typeof Sentry.setContext;
    $sentrySetUser: typeof Sentry.setUser;
    $sentrySetTag: typeof Sentry.setTag;
    $sentryAddBreadcrumb: typeof Sentry.addBreadcrumb;
    $sentryCaptureException: typeof Sentry.captureException;
  }
}

declare module "#app" {
  interface _NuxtApp {
    $sentrySetContext: typeof Sentry.setContext;
    $sentrySetUser: typeof Sentry.setUser;
    $sentrySetTag: typeof Sentry.setTag;
    $sentryAddBreadcrumb: typeof Sentry.addBreadcrumb;
    $sentryCaptureException: typeof Sentry.captureException;
  }
}

declare module "vue" {
  interface ComponentCustomProperties {
    $sentrySetContext: typeof Sentry.setContext;
    $sentrySetUser: typeof Sentry.setUser;
    $sentrySetTag: typeof Sentry.setTag;
    $sentryAddBreadcrumb: typeof Sentry.addBreadcrumb;
    $sentryCaptureException: typeof Sentry.captureException;
  }
}

export default defineNuxtPlugin(async (nuxtApp) => {
  if (nuxtApp.$config.public.PROD && `${nuxtApp.$config.public.SENTRY_ENABLED}`.toLowerCase() === "true") {
    console.info("Sentry Enabled...");
    init({
      app: nuxtApp.vueApp,
      autoSessionTracking: true,
      dsn: nuxtApp.$config.public.SENTRY_DSN,
      release: nuxtApp.$config.public.SENTRY_RELEASE,
      environment: nuxtApp.$config.public.SENTRY_ENVIRONMENT,
      integrations: [
        feedbackIntegration({
          autoInject: nuxtApp.$config.public.SENTRY_SHOW_FEEDBACK === "True",
          // Additional SDK configuration goes in here, for example:
          colorScheme: "system",
          isNameRequired: true,
          enableScreenshot: true,
          useSentryUser: {
            email: "username",
            name: "fullName",
          },
        }),
        browserTracingIntegration({
          router: nuxtApp.$router as Router,
          routeLabel: "path",
        }),
        replayIntegration({
          networkDetailAllowUrls: [nuxtApp.$config.public.API_URL, window.location.origin + "/api"],
          networkRequestHeaders: ["Nexcor-Plant"],
          networkCaptureBodies: true,
          maskAllText: false,
          maskAllInputs: false,
        }),
        httpClientIntegration({}),
        reportingObserverIntegration(),
      ],
      beforeSend(event) {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception && event.event_id) {
          showReportDialog({ eventId: event.event_id });
        }
        return event;
      },
      tracePropagationTargets: [nuxtApp.$config.public.SENTRY_TRACE_PROPAGATION_TARGET],
      trackComponents: true,
      hooks: ["activate", "create", "destroy", "mount", "update"],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.5,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.5,
      replaysOnErrorSampleRate: 1,
    });

    addBreadcrumb({
      category: "version",
      level: "log",
      message: window.ckVersion(),
    });

    return {
      provide: {
        sentrySetContext: setContext,
        sentrySetUser: setUser,
        sentrySetTag: setTag,
        sentryAddBreadcrumb: addBreadcrumb,
        sentryCaptureException: captureException,
      },
    };
  }

  return {
    provide: {
      sentrySetContext: (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _name: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _context: {
          [key: string]: unknown;
        } | null,
      ) => {},
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      sentrySetUser: (_user: User | null) => {},
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      sentrySetTag: (_key: string, _value: Primitive) => {},
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      sentryAddBreadcrumb: (_breadcrumb: Breadcrumb) => {},
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      sentryCaptureException: (_exception: unknown, _captureContext?: CaptureContext) => "",
    },
  };
});
