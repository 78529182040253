import type { RuntimeConfig } from "nuxt/schema";

export type NexcorAccountInfo = {
  username: string;
  name: string;
  id: string;
};

export default abstract class BaseAuthClass {
  config: RuntimeConfig;
  constructor(config: RuntimeConfig) {
    this.config = config;
  }
  abstract initialize(): Promise<void>;
  abstract login(): Promise<boolean>;
  abstract logout(): Promise<boolean>;
  abstract refreshToken(): Promise<string>;
  abstract getToken(): Promise<string>;
  abstract getAccounts(): Promise<NexcorAccountInfo[]>;
  abstract isAuthed(): Promise<boolean>;
  abstract clearData(): Promise<void>;
}
