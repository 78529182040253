import type { ComputedRef } from "vue";

export const LANG_DIR_SYMBOL = Symbol("langDir");
export function useProvideLangDir(value: ComputedRef<"rtl" | "ltr">) {
  provide(LANG_DIR_SYMBOL, value);
}

export function useInjectLangDir() {
  return inject(
    LANG_DIR_SYMBOL,
    computed(() => "ltr"),
  );
}

export const NESTED_CHECKBOX_REGISTER = Symbol("register");
export const NESTED_CHECKBOX_UNREGISTER = Symbol("unregister");
export const NESTED_IDS_REGISTER = Symbol("registerIds");
export const NESTED_IDS_UNREGISTER = Symbol("unregisterIds");
