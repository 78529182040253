import BaseAuthClass from "~/auth/-base-auth-class.ts";
import {
  AUTH_PROVIDER_STORAGE_KEY,
  PROVIDERS,
  PROVIDER_KEYS,
  type PROVIDER_NAMES,
  type IAuthProvider,
} from "~/auth/-constants.ts";
import type { RuntimeConfig } from "nuxt/schema";
import { useGlobalNotification } from "~/store/global-notification.ts";
import { type captureException } from "@sentry/vue";
import { devConsole } from "~/utils/logging.ts";
import type TestAuth from "~/auth/providers/test-auth.ts";

export default class Auth extends BaseAuthClass {
  #currentProvider: BaseAuthClass | undefined;
  #providerPromise: Promise<IAuthProvider<BaseAuthClass>> | undefined;
  #ready: boolean;
  #notify: ReturnType<typeof useGlobalNotification>;
  #sentry: {
    captureException: typeof captureException;
  };
  constructor(config: RuntimeConfig) {
    super(config);
    const { $sentryCaptureException } = useNuxtApp();
    this.#notify = useGlobalNotification();
    this.#sentry = {
      captureException: $sentryCaptureException,
    };
    this.#currentProvider = undefined;
    this.#providerPromise = undefined;
    this.#ready = false;
    // Wrapping to check for prod so that it will compile out correctly
    if (import.meta.env.DEV) {
      PROVIDERS["test"] = {
        validate: (config) => config.public.VITEST === "true" || import.meta.env.VITEST,
        import: async () => (await import("./providers/test-auth.ts")).default as IAuthProvider<TestAuth>,
      };
      PROVIDER_KEYS.push("test");
      if (this.config.public.VITEST === "true" || import.meta.env.VITEST) {
        this.provider = "test";
      }
    }
  }

  get validProviders() {
    if (!this.config) return [];
    return PROVIDER_KEYS.filter((name) => PROVIDERS[name]!.validate(this.config));
  }

  get provider() {
    let provider = (localStorage.getItem(AUTH_PROVIDER_STORAGE_KEY) || "") as PROVIDER_NAMES | "";
    if (provider !== "" && !this.validProviders.includes(provider)) {
      const badProvider = provider;
      this.clearData().then(() => {
        devConsole.warn(`${badProvider} is not a valid auth provider!`);
        this.#sentry.captureException(new Error(`${badProvider} is not a valid auth provider!`));
      });
      provider = "";
    }
    return provider;
  }

  set provider(value: PROVIDER_NAMES | "") {
    if (value === "" || this.validProviders.includes(value)) {
      localStorage.setItem(AUTH_PROVIDER_STORAGE_KEY, value);
    }
  }

  isReady() {
    return this.#ready;
  }
  async initialize() {
    // if we have already started the provider.
    if (this.#currentProvider) {
      this.#ready = true;
      return;
    }
    if (this.provider !== "" && this.validProviders.includes(this.provider)) {
      this.#providerPromise = PROVIDERS[this.provider]!.import();
      try {
        const AuthProviderClass = await this.#providerPromise;
        this.#currentProvider = new AuthProviderClass(this.config) as InstanceType<typeof AuthProviderClass>;
        await this.#currentProvider.initialize();
      } catch (e) {
        devConsole.error(e);
        this.#sentry.captureException(e);
        await this.clearData();
        window.location.href = "/login";
      }
    }
    this.#ready = true;
  }

  async login() {
    if (this.#currentProvider) {
      try {
        return await this.#currentProvider.login();
      } catch (e) {
        await this.clearData();
        this.#sentry.captureException(e);
        this.#notify.showError("Something went wrong while trying to log you in. Please try again.");
        return false;
      }
    }
    return false;
  }

  async logout() {
    this.provider = "";
    this.#providerPromise = undefined;
    if (this.#currentProvider) {
      return await this.#currentProvider.logout();
    }
    this.#currentProvider = undefined;
    return true;
  }

  async refreshToken() {
    if (this.#currentProvider) {
      return this.#currentProvider.refreshToken();
    }
    return "";
  }

  async getToken() {
    if (this.#currentProvider) {
      return this.#currentProvider.getToken();
    }
    return "";
  }

  async getAccounts() {
    if (this.#currentProvider) {
      return this.#currentProvider.getAccounts();
    }
    return [];
  }

  async isAuthed() {
    if (this.#currentProvider) {
      return this.#currentProvider.isAuthed();
    }
    return false;
  }

  async clearData() {
    if (this.#currentProvider) {
      await this.#currentProvider.clearData();
    } else {
      window.localStorage.clear();
    }
  }
}
