import {
  useLocalFetch,
  useCachedFetch,
  type GeneratedFetch,
  type CachedFetchOptions,
} from "~/composables/apiHandler.ts";
import {
  getEquipmentLookupList,
  getEquipmentLookupListForInspection,
  getVendorLookupList,
  getEquipmentLineList,
  getWorkGroupList,
  type EquipLookupView,
  type EquipLookupSimpleView,
} from "~/connectables/fetch/equipmentAreaManagement.ts";
import { getDepartmentList } from "~/connectables/fetch/employeeManagement.ts";
import { defineStore } from "pinia";
import { usePresortedLists } from "~/composables/presortedLists.ts";

export const useEquipmentLookupListStore = defineStore("EquipmentLookupList", () => {
  const { fetch: originalFetch, promise, ...theRest } = useLocalFetch("getEquipmentLookupList", getEquipmentLookupList);
  const { cachedFetch, voidCache } = useCachedFetch(originalFetch, promise);

  const { sorted } = usePresortedLists<EquipLookupView>(theRest.data, { numericKey: "number", alphaKey: "name" });

  //TODO: make a hashmap lookup by number

  return { fetch: cachedFetch, voidCache, promise, sorted, ...theRest };
});

export const useEquipmentLookupListForInspectionsStore = defineStore("EquipmentLookupListForInspections", () => {
  const {
    fetch: originalFetch,
    promise,
    ...theRest
  } = useLocalFetch("getEquipmentLookupListForInspection", getEquipmentLookupListForInspection);

  const { cachedFetch, voidCache } = useCachedFetch(originalFetch, promise);

  //TODO: make a list built from the hashmap of useEquipmentLookupListStore

  const { sorted } = usePresortedLists<EquipLookupSimpleView>(theRest.data, { numericKey: "number", alphaKey: "name" });
  return { fetch: cachedFetch, voidCache, promise, sorted, ...theRest };
});

function defineCachedFetchingStore<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T_FetchMethod extends GeneratedFetch<any, any> = GeneratedFetch<any, any>,
  T_FetchType extends FetchResultsData<T_FetchMethod, RESOLVED_STATUS> = FetchResultsData<
    T_FetchMethod,
    RESOLVED_STATUS
  >,
  T_FetchTypeError extends FetchResultsData<T_FetchMethod, REJECTED_STATUS> = FetchResultsData<
    T_FetchMethod,
    REJECTED_STATUS
  >,
>(id: string, fetchHandler: T_FetchMethod, cacheOptions: CachedFetchOptions = {}) {
  return defineStore(`${id}Store`, () => {
    const {
      fetch: originalFetch,
      promise,
      ...theRest
    } = useLocalFetch<T_FetchMethod, T_FetchType, T_FetchTypeError>(id, fetchHandler);
    const { cachedFetch, voidCache } = useCachedFetch<T_FetchType, T_FetchTypeError>(
      originalFetch,
      promise,
      cacheOptions,
    );
    return { fetch: cachedFetch, voidCache, promise, ...theRest };
  });
}

export const useVendorLookupListStore = defineCachedFetchingStore("getVendorLookupList", getVendorLookupList);
export const useEquipmentLineListStore = defineCachedFetchingStore("getEquipmentLineList", getEquipmentLineList);
export const useWorkGroupListStore = defineCachedFetchingStore("getWorkGroupList", getWorkGroupList);
export const useDepartmentListStore = defineCachedFetchingStore("getDepartmentList", getDepartmentList);
