import { checkWorkpackCloseStatus } from "~/connectables/fetch/transactions.ts";
import { useLocalFetch, FETCH_STATE } from "~/composables/apiHandler.ts";
import { defineStore } from "pinia";
import { isString } from "assertate";
import { useCompanyInfoStore } from "~/store/company-info.ts";
import { TimeInMS } from "~/utils/dateTimeHelpers.ts";
import { useI18n } from "vue-i18n";
import {
  NotificationType,
  useGlobalNotification,
  PERSISTENT_TYPES,
  type NotificationHandle,
} from "~/store/global-notification.ts";

export const useCheckWorkpackCloseStatusStore = defineStore("checkWorkpackCloseStatusStore", () => {
  const globalNotifications = useGlobalNotification();
  const companyInfo = useCompanyInfoStore();
  const nuxtApp = useNuxtApp();
  const handler = useLocalFetch("checkWorkpackCloseStatus", checkWorkpackCloseStatus);
  const { t } = useI18n();

  const __prefixT = (key: string, params: Record<string, unknown>) =>
    t(`notifications.checkWorkpackCloseStatus.${key}`, params);

  // "noCloseNecessary" | "successfullyClosed" | "inProgress" | "inWorksheetPost" | "inCarPost" | "inDailyDutyPost" | "inCallWorkPost" | "inCsbdPost" | "inRecreatingDailyDuties" | "inRefreshingCars" | "inWorksheetCreate" | "validationFailed" | "postingFailed" | "workCreateFailed" | "operationError"

  const IN_PROGRESS = [
    "inProgress",
    "inWorksheetPost",
    "inCarPost",
    "inDailyDutyPost",
    "inCallWorkPost",
    "inCsbdPost",
    "inRecreatingDailyDuties",
    "inRefreshingCars",
    "inWorksheetCreate",
  ].map((e) => e.toUpperCase());

  const ERRORS = ["validationFailed", "postingFailed", "workCreateFailed", "operationError"].map((e) =>
    e.toUpperCase(),
  );

  const SUCCESS = ["noCloseNecessary", "successfullyClosed"].map((e) => e.toUpperCase());

  const inProgress = computed(() => {
    const responseStatus = handler.data.value?.status?.toUpperCase() ?? "";
    return IN_PROGRESS.includes(responseStatus);
  });
  const successfullyClosed = computed(() => {
    const responseStatus = handler.data.value?.status?.toUpperCase() ?? "";
    return SUCCESS.includes(responseStatus);
  });

  const endedInError = computed(() => {
    const responseStatus = handler.data.value?.status?.toUpperCase() ?? "";
    return ERRORS.includes(responseStatus);
  });
  const validationFailed = computed(
    () => handler.data.value?.status?.toUpperCase() === "validationFailed".toUpperCase(),
  );
  const operationError = computed(() => handler.data.value?.status?.toUpperCase() === "operationError".toUpperCase());
  /**
   * Manually put our fetch handler into a state that specifies that it is in progress.
   * This allows us to delay the first call for `checkWorkpackStatus` by a slight amount to negate a race condition on the back end.
   */
  function setInProgressLocal() {
    handler.fetchState.value = FETCH_STATE.RESOLVED;
    handler.response.value = {
      status: 200,
      headers: new Headers(),
      data: {
        numberOfBreakDown: 0,
        numberOfBreakDownsUnableToClose: 0,
        numberOfCallWork: 0,
        numberOfCallWorkUnableToClose: 0,
        numberOfCars: 0,
        numberOfCarsUnableToClose: 0,
        numberOfDailyDuties: 0,
        numberOfDailyDutiesUnableToClose: 0,
        numberOfWorksheets: 0,
        numberOfWorksheetsUnableToClose: 0,
        problems: [],
        status: "InProgress" as unknown as "inProgress",
        worksheetsCreated: 0,
        dateFor: "",
        numberOfWorkItemsToResolve: 0,
        numberOfWorkItemsResolved: 0,
        percentWorkResolved: 0,
        maxWorksheetsToCreate: 0,
        percentWorksheetsCreates: 0,
      },
    };
  }
  const progress = computed(() => {
    if (handler.data.value?.status?.toUpperCase() === "inWorksheetPost".toUpperCase())
      return handler.data.value.percentWorkResolved;
    return handler.data?.value?.percentWorksheetsCreates;
  });

  let notificationHandler: NotificationHandle | undefined;
  let timeoutId: NodeJS.Timeout | undefined;

  nuxtApp.hook("ckClient:plant-change", () => {
    if (notificationHandler) {
      notificationHandler.cancelTimeout();
      notificationHandler.hide();
      notificationHandler.close();
      notificationHandler = undefined;
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = undefined;
      }
    }
  });

  async function checkWorkpackStatus(notificationID?: string) {
    if (!notificationID) {
      notificationHandler = globalNotifications.showPending(
        __prefixT(handler.data.value?.status ?? "", handler.data.value ?? {}),
        true,
        progress.value,
      );
      notificationHandler.persist(true, PERSISTENT_TYPES.CLOSE_WORKPACK_WEEK);
    } else if (!notificationHandler) {
      notificationHandler = globalNotifications.getNotificationHandler(notificationID);
    }
    if (!notificationHandler) return;

    notificationID = notificationHandler.messageObject.value.id;

    await handler.fetch();

    if (!isString(handler.data.value?.status)) {
      notificationHandler.setType(NotificationType.Error);
      notificationHandler.setMessage(__prefixT("invalid", {}));
      notificationHandler.hideProgress();
      return;
    }

    if (endedInError.value) {
      const errors = handler.data.value?.problems?.map((el) => el.reason).join(". ");
      notificationHandler.setType(NotificationType.Error);
      notificationHandler.setMessage(__prefixT(handler.data.value.status, { errors }));
      notificationHandler.hideProgress();
      return;
    }

    if (successfullyClosed.value) {
      notificationHandler.setType(NotificationType.Success);
      notificationHandler.setMessage(__prefixT(handler.data.value?.status, handler.data.value));
      notificationHandler.hideProgress();
      await companyInfo.fetch();
      return;
    }

    notificationHandler.setType(NotificationType.Pending);
    notificationHandler.setMessage(__prefixT(handler.data.value?.status, handler.data.value));
    notificationHandler.setProgress(progress.value ?? 0);
    timeoutId = setTimeout(() => checkWorkpackStatus(notificationID), TimeInMS.TEN_SECONDS);
  }

  return {
    ...handler,
    checkWorkpackStatus,
    setInProgressLocal,
    inProgress,
    successfullyClosed,
    validationFailed,
    operationError,
  };
});
