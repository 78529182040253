export enum MenuNames {
  Main = "main",
  Home = "home",
  KLEANZConnect = "KLEANZConnect",
  Dashboard = "dashboard",
  TaskScheduling = "task-scheduling",
  CreateWorksheets = "create-worksheets",
  CorrectiveActions = "corrective-actions",
  Workpacks = "workpacks",
  Reporting = "reporting",
  Settings = "settings",
  Tutorials = "tutorials",
}

export type CurrentMenu = MenuNames | null;

export const mainMenuNavigation: MainMenuNavigation = [
  {
    key: MenuNames.Home,
    label: "sideMenu.home",
    icon: "fa6-solid:house-chimney",
    url: "/home",
    order: 10,
    subMenu: false,
    external: false,
  },
  {
    key: MenuNames.KLEANZConnect,
    label: "sideMenu.kleanzConnect",
    external: true,
    icon: "",
    url: "",
    order: 20,
    subMenu: false,
  },
  {
    key: MenuNames.Dashboard,
    label: "sideMenu.dashboard",
    icon: "fa6-solid:chart-line",
    external: true,
    url: "",
    order: 30,
    subMenu: false,
  },
  {
    key: MenuNames.TaskScheduling,
    label: "sideMenu.taskScheduling",
    icon: "fa6-solid:list-ul",
    url: "/task-scheduling",
    order: 40,
    subMenu: true,
    external: false,
  },
  {
    key: MenuNames.CreateWorksheets,
    label: "sideMenu.createWorksheets",
    icon: "material-symbols:edit-document-outline-rounded",
    url: "/create-worksheets",
    order: 50,
    subMenu: true,
    external: false,
  },
  {
    key: MenuNames.CorrectiveActions,
    label: "sideMenu.correctiveActions",
    icon: "lucide:wrench",
    url: "/corrective-actions/corrective-action-requests",
    order: 60,
    subMenu: false,
    external: false,
  },
  {
    key: MenuNames.Workpacks,
    label: "sideMenu.workpacks",
    icon: "material-symbols:folder-copy-outline-rounded",
    url: "/workpacks",
    order: 70,
    subMenu: true,
    external: false,
  },
  {
    key: MenuNames.Reporting,
    label: "sideMenu.reporting",
    icon: "fa6-solid:chart-pie",
    url: "/reporting",
    order: 80,
    subMenu: true,
    external: false,
  },
  {
    key: MenuNames.Settings,
    label: "sideMenu.settings",
    icon: "fluent:settings-32-regular",
    url: "/settings",
    order: 90,
    subMenu: true,
    external: false,
  },
  {
    key: MenuNames.Tutorials,
    label: "sideMenu.tutorials",
    icon: "fluent:play-32-regular",
    url: "tutorialLink",
    external: true,
    order: 100,
    subMenu: false,
  },
];

export type MainMenuNavigation = CollapsableMenu[];

export type CollapsableMenu = {
  key: MenuNames;
  label: string;
  icon: string;
  url: string;
  order: number;
  external?: boolean;
  subMenu: boolean;
};

export const subMenuNavigation: SubMenuNavigation = {
  [MenuNames.TaskScheduling]: {
    heading: "sideMenu.taskScheduling",
    links: [
      {
        key: "task-lists",
        url: "/task-scheduling/task-lists",
        text: "sideMenu.taskLists",
        order: 10,
      },
      {
        key: "global-tasks",
        url: "/task-scheduling/global-tasks",
        text: "sideMenu.globalTasks",
        order: 20,
      },
      {
        key: "daily-duties",
        url: "/task-scheduling/daily-duties",
        text: "sideMenu.dailyDuties",
        order: 30,
      },
    ],
  },
  [MenuNames.CreateWorksheets]: {
    heading: "sideMenu.createWorksheets",
    links: [
      {
        key: "scheduled-worksheets",
        url: "/create-worksheets/scheduled-worksheets",
        text: "sideMenu.scheduledWorksheets",
        order: 10,
      },
      {
        key: "on-demand-changeover-worksheets",
        url: "/create-worksheets/on-demand-changeover-worksheets",
        text: "sideMenu.onDemandAndChangeoverWorksheets",
        order: 20,
      },
    ],
  },
  [MenuNames.CorrectiveActions]: {
    heading: "sideMenu.correctiveActions",
    links: [
      {
        key: "corrective-actions",
        url: "/corrective-actions/corrective-action-requests",
        text: "sideMenu.correctiveActionRequests",
        order: 10,
      },
    ],
  },
  [MenuNames.Workpacks]: {
    heading: "sideMenu.workpacks",
    links: [
      {
        key: "assign-workpacks",
        url: "/workpacks/assign-workpacks",
        text: "sideMenu.assignWorkpacks",
        order: 10,
      },
      {
        key: "list-workpacks",
        url: "/workpacks/list-workpacks",
        text: "sideMenu.workpacks",
        order: 20,
      },
      {
        key: "close-workpack-week",
        url: "/workpacks/close-workpack-week",
        text: "sideMenu.closeWorkpackWeek",
        order: 30,
      },
    ],
  },
  [MenuNames.Reporting]: {
    heading: "sideMenu.reporting",
    subMenus: [
      {
        heading: "sideMenu.laborReports",
        key: "labor-reports",
        order: 10,
        links: [
          {
            key: "worksheet-completion-percentage",
            url: "/reporting/labor/worksheet-completion-percentage",
            text: "sideMenu.worksheetCompletionPercentage",
            order: 10,
          },
          {
            key: "total-resource-utilization",
            url: "/reporting/labor/total-resource-utilization",
            text: "sideMenu.totalResourceUtilization",
            order: 20,
          },
          {
            key: "labor-history-forecast",
            url: "/reporting/labor/labor-history-forecast",
            text: "sideMenu.laborHistoryAndLaborForecast",
            order: 30,
          },
          {
            key: "labor-history-report",
            url: "/reporting/labor/labor-history-report",
            text: "sideMenu.laborHistory",
            order: 40,
          },
        ],
      },
      {
        heading: "sideMenu.inspectionReports",
        key: "inspection-reports",
        order: 20,
        links: [
          {
            key: "events",
            url: "/reporting/inspection/events",
            text: "sideMenu.eventsReport",
            order: 10,
          },
          {
            key: "events-by-category",
            url: "/reporting/inspection/events-by-category",
            text: "sideMenu.eventsByCategory",
            order: 20,
          },
          {
            key: "events-by-type",
            url: "/reporting/inspection/events-by-type",
            text: "sideMenu.eventsByType",
            order: 30,
          },
          {
            key: "events-by-zone",
            url: "/reporting/inspection/events-by-zone",
            text: "sideMenu.eventsByZone",
            order: 40,
          },
          {
            key: "cars-by-inspection",
            url: "/reporting/inspection/cars-by-inspection",
            text: "sideMenu.carsByInspection",
            order: 50,
          },
        ],
      },
      {
        heading: "sideMenu.trainingReports",
        key: "training-reports",
        order: 30,
        links: [
          {
            key: "training-log",
            url: "/reporting/training/training-log",
            text: "pages.trainingLogReport",
            order: 10,
          },
          {
            key: "employee-by-skill",
            url: "/reporting/training/employee-by-skill",
            text: "pages.employeesBySkillReport",
            order: 20,
          },
        ],
      },
    ],
  },
  [MenuNames.Settings]: {
    heading: "sideMenu.settings",
    subMenus: [
      {
        heading: "sideMenu.equipmentAreaInformation",
        key: "equipment-area-information",
        order: 10,
        links: [
          {
            key: "equipment-areas",
            url: "/settings/equipment-area-information/equipment-areas",
            text: "sideMenu.equipmentAreas",
            order: 10,
          },
          {
            key: "production-lines",
            url: "/settings/equipment-area-information/production-lines",
            text: "sideMenu.productionLines",
            order: 20,
          },
          {
            key: "work-groups",
            url: "/settings/equipment-area-information/work-groups",
            text: "sideMenu.workGroups",
            order: 30,
          },
          {
            key: "corporate-categories",
            url: "/settings/equipment-area-information/corporate-categories",
            text: "sideMenu.corporateCategories",
            order: 40,
          },
          {
            key: "manufacturers",
            url: "/settings/equipment-area-information/manufacturers",
            text: "sideMenu.manufacturers",
            order: 50,
          },
        ],
      },
      {
        heading: "sideMenu.inspections",
        key: "inspections",
        order: 20,
        links: [
          {
            key: "inspection-type-entry",
            url: "/settings/inspections/inspection-type-entry",
            text: "sideMenu.inspectionTypes",
            order: 10,
          },
          {
            key: "inspection-category-entry",
            url: "/settings/inspections/inspection-category-entry",
            text: "sideMenu.inspectionCategories",
            order: 20,
          },
          {
            key: "inspection-zone-entry",
            url: "/settings/inspections/inspection-zone-entry",
            text: "sideMenu.inspectionZones",
            order: 30,
          },
        ],
      },
      {
        key: "material-entry",
        url: "/settings/material-entry",
        text: "sideMenu.materials",
        order: 30,
      },
      {
        heading: "sideMenu.employees",
        key: "employees",
        order: 40,
        links: [
          {
            key: "employee-information",
            url: "/settings/employees/employee-information",
            text: "sideMenu.employeeInformation",
            order: 10,
          },
          {
            key: "training-types",
            url: "/settings/employees/training-types",
            text: "sideMenu.trainingTypes",
            order: 20,
          },
          {
            key: "skill-types",
            url: "/settings/employees/skill-types",
            text: "sideMenu.skillTypes",
            order: 30,
          },
          {
            key: "departments",
            url: "/settings/employees/departments",
            text: "sideMenu.departments",
            order: 40,
          },
        ],
      },
      {
        key: "document-control-entry",
        url: "/settings/document-control-entry",
        text: "sideMenu.documentControlEntry",
        order: 50,
      },
    ],
  },
};

export type SubMenuNavigation = {
  [MenuNames.Main]?: null;
  [MenuNames.Home]?: null;
  [MenuNames.KLEANZConnect]?: null;
  [MenuNames.Dashboard]?: null;
  [MenuNames.TaskScheduling]: Menu;
  [MenuNames.CreateWorksheets]: Menu;
  [MenuNames.CorrectiveActions]: Menu;
  [MenuNames.Workpacks]: Menu;
  [MenuNames.Reporting]: MenuWithSubMenus;
  [MenuNames.Settings]: MenuWithSubMenus;
  [MenuNames.Tutorials]?: null;
};

type Menu = {
  heading: string;
  links: LinkData[];
};

type SubMenu = {
  heading: string;
  key: string;
  order: number;
  links: LinkData[];
};

type MenuWithSubMenus = {
  heading: string;
  subMenus: (SubMenu | LinkData)[];
};

type LinkData = {
  key: string;
  url: string;
  text: string;
  order: number;
};
