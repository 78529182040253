import { Auth } from "~/auth";
import { setUser } from "@sentry/vue";

declare module "#app" {
  interface NuxtApp {
    $auth: Auth;
  }
}

declare module "vue" {
  interface ComponentCustomProperties {
    $auth: Auth;
  }
}
export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();
  const localePath = useLocalePath();
  const auth = new Auth(config);
  await auth.initialize();

  addRouteMiddleware(
    "auth",
    async (to) => {
      const isAuthed = await auth.isAuthed();
      if (auth && auth.isReady()) {
        // Need login
        if (!isAuthed && !to.meta.allowAnonymous && !to.path.includes("/login")) {
          return navigateTo(localePath("/login"));
        } else {
          // Tell Sentry the user
          const accounts = await auth.getAccounts();
          if (accounts && accounts.length > 0) {
            setUser({
              fullName: accounts[0].name,
              id: accounts[0].id,
              username: accounts[0].username,
            });
          }
        }
      }
    },
    { global: true },
  );

  return {
    provide: {
      auth,
    },
  };
});
