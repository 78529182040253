// Defines a plugin to get configuration from the server
import { getClientConfiguration } from "~/connectables/fetch/configuration.ts";
import { defineNuxtPlugin } from "#app";
import { useGlobalNotification } from "~/store/global-notification.ts";

declare global {
  interface Window {
    ckVersion: () => string;
  }
}

const PROTECTED_KEYS = ["API_OAZAPFTS_URL", "API_URL", "apiUrl", "SENTRY_RELEASE", "BUILD_TIMESTAMP"];
export default defineNuxtPlugin({
  setup: async (nuxtApp) => {
    const globalNotification = useGlobalNotification();
    // Get the existing runtime config
    const rt = nuxtApp.$config;

    const VERSION_STRING = `Version: ${rt.public.VERSION}, Built At: ${rt.public.BUILD_TIMESTAMP}`;

    window.ckVersion = function () {
      // eslint-disable-next-line
      console.info(VERSION_STRING);
      return VERSION_STRING;
    };

    const response = await getClientConfiguration({ baseUrl: rt.public.API_OAZAPFTS_URL }).catch((e) => {
      devConsole.error(e);
      devConsole.dir(e);
      globalNotification.showError(
        "The settings API has been incorrectly configured. Please contact your administrator.",
      );
    });

    // Go through the values and add/override settings
    if (response?.status === 200) {
      for (const [key, value] of Object.entries(response.data)) {
        if (!PROTECTED_KEYS.includes(key)) {
          rt.public[key] = value;
        }
      }
    }
  },
});
