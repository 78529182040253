const ArgsGroupRX = `\\((.*)\\)\\s*`;
const ParseNamedFunctionRX = `function\\s*(?:\\S+\\s*)?${ArgsGroupRX}\\{`;
const ParseArrowFunctionRX = `${ArgsGroupRX}=>\\s*.*`;
const ParseFunctionRX = `${ParseNamedFunctionRX}|${ParseArrowFunctionRX}`;
// We only need the first one, and because this is shared, we do not want to set the g flag.
const ParseFunction = new RegExp(ParseFunctionRX);
//const ParseFunction = /function\s*(?:\S+\s*)?\((.+)\)\s*\{|\((.*)\)\s*=>\s*.*/;

/**
 * Selects single level objects and arrays. This cannot selected nested objects or arrays. Nor will it.
 */
const SelectObjectOrArrayRX = `[[{][^=}\\]]+[}\\]]`;
/**
 * Selects ` = ` for default assignments.
 */
const AssignmentRX = `\\s*=?\\s*`;
/**
 * Selects only variable names. Does not allow for utf-8 support, but also not going to let it.
 */
const VariableNameOnlyRX = `[a-zA-Z_$][A-Za-z0-9_$]*`;
/** Selects everything up to a comma */
const UpToCommaRX = `[^,]*`;
const ParseArgsRX = `(${VariableNameOnlyRX}|${SelectObjectOrArrayRX})(${AssignmentRX}(?:${SelectObjectOrArrayRX}|${UpToCommaRX}))?`;
const ParseArgs = new RegExp(ParseArgsRX, "g");
// eslint-disable-next-line @typescript-eslint/ban-types
export function argumentParser(func: Function): string[] {
  // Fetch methods are generated by Oazapfts. We need to check if there are optional parameters with a
  // set default. For those methods, if we don't add undefined before the ApiRequestOpts object, it will
  // set the ApiRequestOpts object properties as parameters.
  const paramMatch = ParseFunction.exec(func.toString()) ?? [];
  const params = paramMatch.slice(1).find((v) => typeof v === "string") ?? "";
  return params.match(ParseArgs) ?? [];
}
