/**
 * PlantApi
 * 1.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.Defaults<Oazapfts.CustomHeaders> = {
    headers: {},
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type PlantInfo = {
    plantId: number;
    plantName: string;
    plantNumber: string;
    licenseName: string;
    licenseId: number;
};
export type DataStatus = "success" | "notValid" | "exception" | "error" | "inUse" | "notFound" | "noChange" | "inProgress" | "invalidPage" | "duplicateEntity" | "noContent";
export type ErrorResponse = {
    message: string;
    kind: DataStatus;
    exception: string | null;
    stackTrace: string | null;
    exceptionType: string;
    innerException: string | null;
    validationErrors: {
        [key: string]: string[];
    } | null;
};
export type UserModel = {
    userName: string;
    mobileUserName: string;
    email: string;
    userId: number;
    isValidForPlant: boolean;
};
export function getUserPlantList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PlantInfo[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/user/plants", {
        ...opts
    });
}
export function getUser(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserModel;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/user", {
        ...opts
    });
}
export function postApiReportLabelTestpdf(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/api/report/label/testpdf", {
        ...opts,
        method: "POST"
    });
}
export function getApiReportTestbulk(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/api/report/testbulk", {
        ...opts
    });
}
export function getApiReportTrainingLog(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/api/report/training/log", {
        ...opts
    });
}
export function getApiReportSupervisorscorecard(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/api/report/supervisorscorecard", {
        ...opts
    });
}
export function getApiReportInspectionhistory(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/api/report/inspectionhistory", {
        ...opts
    });
}
export function getApiReportEmployeeSkill(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/api/report/employee/skill", {
        ...opts
    });
}
