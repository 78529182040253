import { useIsReady, useLocalFetch } from "~/composables/apiHandler.ts";
import { defineStore } from "pinia";
import { getCompanyInformation } from "~/connectables/fetch/companyInformation.ts";

export const useCompanyInfoStore = defineStore("getCompanyInformation", () => {
  const companyInfo = useLocalFetch("getCompanyInformation", getCompanyInformation);
  const isReady = useIsReady(companyInfo);
  return {
    isReady,
    ...companyInfo,
  };
});
