<script setup lang="ts">
import { getUID } from "~/utils/uniqueID";

const props = defineProps({
  value: {
    type: Number,
    default: 0,
  },
});
const percentage = computed(() => props.value + "%");
const id = getUID("progress");
</script>

<template>
  <div class="flex flex-col items-center pb-2">
    <label :for="id">{{ percentage }}</label>
    <progress :id="id" max="100" :value="value">{{ percentage }}</progress>
  </div>
</template>

<style scoped lang="postcss">
progress[value] {
  --color: var(--color-nblue-2);
  --background: var(--color-nblue-6);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid var(--color-ngray-3);
  width: 100px;
  margin: 0 10px;
  border-radius: 10em;
  background: var(--background);
}
progress[value]::-webkit-progress-bar {
  border-radius: 10em;
  background: var(--background);
}
progress[value]::-webkit-progress-value {
  border-radius: 10em;
  background: var(--color);
}
</style>
