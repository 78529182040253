import { default as _91number_93AHQEkJu905Meta } from "/src/ckClient/pages/corrective-actions/corrective-action-requests/[number].vue?macro=true";
import { default as indexkUKduVE3qCMeta } from "/src/ckClient/pages/corrective-actions/corrective-action-requests/index.vue?macro=true";
import { default as newjTik1biIkYMeta } from "/src/ckClient/pages/corrective-actions/corrective-action-requests/new.vue?macro=true";
import { default as indexW39eRAPxQcMeta } from "/src/ckClient/pages/create-worksheets/on-demand-changeover-worksheets/index.vue?macro=true";
import { default as indexpLgQNDVrAJMeta } from "/src/ckClient/pages/create-worksheets/scheduled-worksheets/index.vue?macro=true";
import { default as indexIEgv4dcMYYMeta } from "/src/ckClient/pages/home/index.vue?macro=true";
import { default as indexEgpVl8bumdMeta } from "/src/ckClient/pages/index.vue?macro=true";
import { default as _91number_93rf0g4QWm8WMeta } from "/src/ckClient/pages/labor-history-entry/[number].vue?macro=true";
import { default as indexrKOZFVqgrEMeta } from "/src/ckClient/pages/labor-history-entry/index.vue?macro=true";
import { default as new3ZorPcFUUsMeta } from "/src/ckClient/pages/labor-history-entry/new.vue?macro=true";
import { default as loginfYRDvrTUKnMeta } from "/src/ckClient/pages/login.vue?macro=true";
import { default as cars_45by_45inspection18ntwvEoLjMeta } from "/src/ckClient/pages/reporting/inspection/cars-by-inspection.vue?macro=true";
import { default as events_45by_45categorycLNkslKXMuMeta } from "/src/ckClient/pages/reporting/inspection/events-by-category.vue?macro=true";
import { default as events_45by_45typeOb1ReI7HBxMeta } from "/src/ckClient/pages/reporting/inspection/events-by-type.vue?macro=true";
import { default as events_45by_45zone0Ikw9pJYzfMeta } from "/src/ckClient/pages/reporting/inspection/events-by-zone.vue?macro=true";
import { default as eventskhaA5iFJ8nMeta } from "/src/ckClient/pages/reporting/inspection/events.vue?macro=true";
import { default as labor_45history_45forecastjGfOtlw7WkMeta } from "/src/ckClient/pages/reporting/labor/labor-history-forecast.vue?macro=true";
import { default as labor_45history_45reportfNq7O4AYcqMeta } from "/src/ckClient/pages/reporting/labor/labor-history-report.vue?macro=true";
import { default as total_45resource_45utilizationAimhmRNSpPMeta } from "/src/ckClient/pages/reporting/labor/total-resource-utilization.vue?macro=true";
import { default as worksheet_45completion_45percentage8hvYFo5pt5Meta } from "/src/ckClient/pages/reporting/labor/worksheet-completion-percentage.vue?macro=true";
import { default as employee_45by_45skillRcPC8oSXcDMeta } from "/src/ckClient/pages/reporting/training/employee-by-skill.vue?macro=true";
import { default as training_45logNGVfqVam6JMeta } from "/src/ckClient/pages/reporting/training/training-log.vue?macro=true";
import { default as indexnDOqJMohUrMeta } from "/src/ckClient/pages/settings/document-control-entry/index.vue?macro=true";
import { default as indexGbncg4g3QAMeta } from "/src/ckClient/pages/settings/employees/default-assignments/index.vue?macro=true";
import { default as indexzahAeCU8R7Meta } from "/src/ckClient/pages/settings/employees/departments/index.vue?macro=true";
import { default as _91number_93CbTYBCVzGvMeta } from "/src/ckClient/pages/settings/employees/employee-information/[number].vue?macro=true";
import { default as indexq0phq9E0KMMeta } from "/src/ckClient/pages/settings/employees/employee-information/index.vue?macro=true";
import { default as newciel8EzYqJMeta } from "/src/ckClient/pages/settings/employees/employee-information/new.vue?macro=true";
import { default as _91code_93R12ObDsSmLMeta } from "/src/ckClient/pages/settings/employees/skill-types/[code].vue?macro=true";
import { default as indextRY2w0H2lzMeta } from "/src/ckClient/pages/settings/employees/skill-types/index.vue?macro=true";
import { default as newEiFmGBPOl8Meta } from "/src/ckClient/pages/settings/employees/skill-types/new.vue?macro=true";
import { default as indexp2UQWW1POuMeta } from "/src/ckClient/pages/settings/employees/training-types/index.vue?macro=true";
import { default as indexpMLgn4RNT4Meta } from "/src/ckClient/pages/settings/equipment-area-information/corporate-categories/index.vue?macro=true";
import { default as _91number_93m2pZSUcXULMeta } from "/src/ckClient/pages/settings/equipment-area-information/equipment-areas/[number].vue?macro=true";
import { default as indexD7luvKcuNIMeta } from "/src/ckClient/pages/settings/equipment-area-information/equipment-areas/index.vue?macro=true";
import { default as new10dyxx8sYyMeta } from "/src/ckClient/pages/settings/equipment-area-information/equipment-areas/new.vue?macro=true";
import { default as _91number_93LGxWw7P3XmMeta } from "/src/ckClient/pages/settings/equipment-area-information/manufacturers/[number].vue?macro=true";
import { default as index4TDeL6AQhHMeta } from "/src/ckClient/pages/settings/equipment-area-information/manufacturers/index.vue?macro=true";
import { default as new59KvFIA6QTMeta } from "/src/ckClient/pages/settings/equipment-area-information/manufacturers/new.vue?macro=true";
import { default as indexzyF9I0FzAYMeta } from "/src/ckClient/pages/settings/equipment-area-information/production-lines/index.vue?macro=true";
import { default as indexBHr2yMvwasMeta } from "/src/ckClient/pages/settings/equipment-area-information/work-groups/index.vue?macro=true";
import { default as indexWyXG8xHFCkMeta } from "/src/ckClient/pages/settings/inspections/inspection-category-entry/index.vue?macro=true";
import { default as _91number_93O23IeWQwoCMeta } from "/src/ckClient/pages/settings/inspections/inspection-type-entry/[number].vue?macro=true";
import { default as index0p3muywXbsMeta } from "/src/ckClient/pages/settings/inspections/inspection-type-entry/index.vue?macro=true";
import { default as newOuPAuEFtayMeta } from "/src/ckClient/pages/settings/inspections/inspection-type-entry/new.vue?macro=true";
import { default as indexnFDiaQR3fLMeta } from "/src/ckClient/pages/settings/inspections/inspection-zone-entry/index.vue?macro=true";
import { default as _91code_93Qb4G37p48DMeta } from "/src/ckClient/pages/settings/material-entry/[code].vue?macro=true";
import { default as indexRHe1CBF1MZMeta } from "/src/ckClient/pages/settings/material-entry/index.vue?macro=true";
import { default as newAsRNkPDHU2Meta } from "/src/ckClient/pages/settings/material-entry/new.vue?macro=true";
import { default as indexNzj4KqZ03dMeta } from "/src/ckClient/pages/task-scheduling/daily-duties/index.vue?macro=true";
import { default as index3nDpb6uWd0Meta } from "/src/ckClient/pages/task-scheduling/global-tasks/index.vue?macro=true";
import { default as indexVkyLDT7F3EMeta } from "/src/ckClient/pages/task-scheduling/task-lists/[number]/index.vue?macro=true";
import { default as librariesAmBZuVrruRMeta } from "/src/ckClient/pages/task-scheduling/task-lists/[number]/libraries.vue?macro=true";
import { default as _91task_937xU3l80XcbMeta } from "/src/ckClient/pages/task-scheduling/task-lists/[number]/task/[task].vue?macro=true";
import { default as newjMxpxklyffMeta } from "/src/ckClient/pages/task-scheduling/task-lists/[number]/task/new.vue?macro=true";
import { default as indexJiYUzboxjMMeta } from "/src/ckClient/pages/task-scheduling/task-lists/index.vue?macro=true";
import { default as index8W5gjmVDfmMeta } from "/src/ckClient/pages/workpacks/assign-workpacks/index.vue?macro=true";
import { default as indextHVhFo5sgnMeta } from "/src/ckClient/pages/workpacks/close-workpack-week/index.vue?macro=true";
import { default as indexWG49FPlFzkMeta } from "/src/ckClient/pages/workpacks/list-workpacks/index.vue?macro=true";
export default [
  {
    name: "corrective-actions-corrective-action-requests-number___en",
    path: "/corrective-actions/corrective-action-requests/:number()",
    meta: _91number_93AHQEkJu905Meta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/[number].vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests-number___es",
    path: "/es/corrective-actions/corrective-action-requests/:number()",
    meta: _91number_93AHQEkJu905Meta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/[number].vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests-number___ar",
    path: "/ar/corrective-actions/corrective-action-requests/:number()",
    meta: _91number_93AHQEkJu905Meta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/[number].vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests-number___ja",
    path: "/ja/corrective-actions/corrective-action-requests/:number()",
    meta: _91number_93AHQEkJu905Meta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/[number].vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests-number___fr",
    path: "/fr/corrective-actions/corrective-action-requests/:number()",
    meta: _91number_93AHQEkJu905Meta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/[number].vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests-number___hi",
    path: "/hi/corrective-actions/corrective-action-requests/:number()",
    meta: _91number_93AHQEkJu905Meta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/[number].vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests-number___pt",
    path: "/pt/corrective-actions/corrective-action-requests/:number()",
    meta: _91number_93AHQEkJu905Meta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/[number].vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests___en",
    path: "/corrective-actions/corrective-action-requests",
    meta: indexkUKduVE3qCMeta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/index.vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests___es",
    path: "/es/corrective-actions/corrective-action-requests",
    meta: indexkUKduVE3qCMeta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/index.vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests___ar",
    path: "/ar/corrective-actions/corrective-action-requests",
    meta: indexkUKduVE3qCMeta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/index.vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests___ja",
    path: "/ja/corrective-actions/corrective-action-requests",
    meta: indexkUKduVE3qCMeta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/index.vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests___fr",
    path: "/fr/corrective-actions/corrective-action-requests",
    meta: indexkUKduVE3qCMeta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/index.vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests___hi",
    path: "/hi/corrective-actions/corrective-action-requests",
    meta: indexkUKduVE3qCMeta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/index.vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests___pt",
    path: "/pt/corrective-actions/corrective-action-requests",
    meta: indexkUKduVE3qCMeta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/index.vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests-new___en",
    path: "/corrective-actions/corrective-action-requests/new",
    meta: newjTik1biIkYMeta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/new.vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests-new___es",
    path: "/es/corrective-actions/corrective-action-requests/new",
    meta: newjTik1biIkYMeta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/new.vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests-new___ar",
    path: "/ar/corrective-actions/corrective-action-requests/new",
    meta: newjTik1biIkYMeta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/new.vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests-new___ja",
    path: "/ja/corrective-actions/corrective-action-requests/new",
    meta: newjTik1biIkYMeta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/new.vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests-new___fr",
    path: "/fr/corrective-actions/corrective-action-requests/new",
    meta: newjTik1biIkYMeta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/new.vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests-new___hi",
    path: "/hi/corrective-actions/corrective-action-requests/new",
    meta: newjTik1biIkYMeta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/new.vue").then(m => m.default || m)
  },
  {
    name: "corrective-actions-corrective-action-requests-new___pt",
    path: "/pt/corrective-actions/corrective-action-requests/new",
    meta: newjTik1biIkYMeta || {},
    component: () => import("/src/ckClient/pages/corrective-actions/corrective-action-requests/new.vue").then(m => m.default || m)
  },
  {
    name: "create-worksheets-on-demand-changeover-worksheets___en",
    path: "/create-worksheets/on-demand-changeover-worksheets",
    meta: indexW39eRAPxQcMeta || {},
    component: () => import("/src/ckClient/pages/create-worksheets/on-demand-changeover-worksheets/index.vue").then(m => m.default || m)
  },
  {
    name: "create-worksheets-on-demand-changeover-worksheets___es",
    path: "/es/create-worksheets/on-demand-changeover-worksheets",
    meta: indexW39eRAPxQcMeta || {},
    component: () => import("/src/ckClient/pages/create-worksheets/on-demand-changeover-worksheets/index.vue").then(m => m.default || m)
  },
  {
    name: "create-worksheets-on-demand-changeover-worksheets___ar",
    path: "/ar/create-worksheets/on-demand-changeover-worksheets",
    meta: indexW39eRAPxQcMeta || {},
    component: () => import("/src/ckClient/pages/create-worksheets/on-demand-changeover-worksheets/index.vue").then(m => m.default || m)
  },
  {
    name: "create-worksheets-on-demand-changeover-worksheets___ja",
    path: "/ja/create-worksheets/on-demand-changeover-worksheets",
    meta: indexW39eRAPxQcMeta || {},
    component: () => import("/src/ckClient/pages/create-worksheets/on-demand-changeover-worksheets/index.vue").then(m => m.default || m)
  },
  {
    name: "create-worksheets-on-demand-changeover-worksheets___fr",
    path: "/fr/create-worksheets/on-demand-changeover-worksheets",
    meta: indexW39eRAPxQcMeta || {},
    component: () => import("/src/ckClient/pages/create-worksheets/on-demand-changeover-worksheets/index.vue").then(m => m.default || m)
  },
  {
    name: "create-worksheets-on-demand-changeover-worksheets___hi",
    path: "/hi/create-worksheets/on-demand-changeover-worksheets",
    meta: indexW39eRAPxQcMeta || {},
    component: () => import("/src/ckClient/pages/create-worksheets/on-demand-changeover-worksheets/index.vue").then(m => m.default || m)
  },
  {
    name: "create-worksheets-on-demand-changeover-worksheets___pt",
    path: "/pt/create-worksheets/on-demand-changeover-worksheets",
    meta: indexW39eRAPxQcMeta || {},
    component: () => import("/src/ckClient/pages/create-worksheets/on-demand-changeover-worksheets/index.vue").then(m => m.default || m)
  },
  {
    name: "create-worksheets-scheduled-worksheets___en",
    path: "/create-worksheets/scheduled-worksheets",
    meta: indexpLgQNDVrAJMeta || {},
    component: () => import("/src/ckClient/pages/create-worksheets/scheduled-worksheets/index.vue").then(m => m.default || m)
  },
  {
    name: "create-worksheets-scheduled-worksheets___es",
    path: "/es/create-worksheets/scheduled-worksheets",
    meta: indexpLgQNDVrAJMeta || {},
    component: () => import("/src/ckClient/pages/create-worksheets/scheduled-worksheets/index.vue").then(m => m.default || m)
  },
  {
    name: "create-worksheets-scheduled-worksheets___ar",
    path: "/ar/create-worksheets/scheduled-worksheets",
    meta: indexpLgQNDVrAJMeta || {},
    component: () => import("/src/ckClient/pages/create-worksheets/scheduled-worksheets/index.vue").then(m => m.default || m)
  },
  {
    name: "create-worksheets-scheduled-worksheets___ja",
    path: "/ja/create-worksheets/scheduled-worksheets",
    meta: indexpLgQNDVrAJMeta || {},
    component: () => import("/src/ckClient/pages/create-worksheets/scheduled-worksheets/index.vue").then(m => m.default || m)
  },
  {
    name: "create-worksheets-scheduled-worksheets___fr",
    path: "/fr/create-worksheets/scheduled-worksheets",
    meta: indexpLgQNDVrAJMeta || {},
    component: () => import("/src/ckClient/pages/create-worksheets/scheduled-worksheets/index.vue").then(m => m.default || m)
  },
  {
    name: "create-worksheets-scheduled-worksheets___hi",
    path: "/hi/create-worksheets/scheduled-worksheets",
    meta: indexpLgQNDVrAJMeta || {},
    component: () => import("/src/ckClient/pages/create-worksheets/scheduled-worksheets/index.vue").then(m => m.default || m)
  },
  {
    name: "create-worksheets-scheduled-worksheets___pt",
    path: "/pt/create-worksheets/scheduled-worksheets",
    meta: indexpLgQNDVrAJMeta || {},
    component: () => import("/src/ckClient/pages/create-worksheets/scheduled-worksheets/index.vue").then(m => m.default || m)
  },
  {
    name: "home___en",
    path: "/home",
    component: () => import("/src/ckClient/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___es",
    path: "/es/home",
    component: () => import("/src/ckClient/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___ar",
    path: "/ar/home",
    component: () => import("/src/ckClient/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___ja",
    path: "/ja/home",
    component: () => import("/src/ckClient/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___fr",
    path: "/fr/home",
    component: () => import("/src/ckClient/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___hi",
    path: "/hi/home",
    component: () => import("/src/ckClient/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home___pt",
    path: "/pt/home",
    component: () => import("/src/ckClient/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexEgpVl8bumdMeta || {},
    component: () => import("/src/ckClient/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexEgpVl8bumdMeta || {},
    component: () => import("/src/ckClient/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ar",
    path: "/ar",
    meta: indexEgpVl8bumdMeta || {},
    component: () => import("/src/ckClient/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ja",
    path: "/ja",
    meta: indexEgpVl8bumdMeta || {},
    component: () => import("/src/ckClient/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexEgpVl8bumdMeta || {},
    component: () => import("/src/ckClient/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___hi",
    path: "/hi",
    meta: indexEgpVl8bumdMeta || {},
    component: () => import("/src/ckClient/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pt",
    path: "/pt",
    meta: indexEgpVl8bumdMeta || {},
    component: () => import("/src/ckClient/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry-number___en",
    path: "/labor-history-entry/:number()",
    component: () => import("/src/ckClient/pages/labor-history-entry/[number].vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry-number___es",
    path: "/es/labor-history-entry/:number()",
    component: () => import("/src/ckClient/pages/labor-history-entry/[number].vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry-number___ar",
    path: "/ar/labor-history-entry/:number()",
    component: () => import("/src/ckClient/pages/labor-history-entry/[number].vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry-number___ja",
    path: "/ja/labor-history-entry/:number()",
    component: () => import("/src/ckClient/pages/labor-history-entry/[number].vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry-number___fr",
    path: "/fr/labor-history-entry/:number()",
    component: () => import("/src/ckClient/pages/labor-history-entry/[number].vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry-number___hi",
    path: "/hi/labor-history-entry/:number()",
    component: () => import("/src/ckClient/pages/labor-history-entry/[number].vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry-number___pt",
    path: "/pt/labor-history-entry/:number()",
    component: () => import("/src/ckClient/pages/labor-history-entry/[number].vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry___en",
    path: "/labor-history-entry",
    meta: indexrKOZFVqgrEMeta || {},
    component: () => import("/src/ckClient/pages/labor-history-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry___es",
    path: "/es/labor-history-entry",
    meta: indexrKOZFVqgrEMeta || {},
    component: () => import("/src/ckClient/pages/labor-history-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry___ar",
    path: "/ar/labor-history-entry",
    meta: indexrKOZFVqgrEMeta || {},
    component: () => import("/src/ckClient/pages/labor-history-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry___ja",
    path: "/ja/labor-history-entry",
    meta: indexrKOZFVqgrEMeta || {},
    component: () => import("/src/ckClient/pages/labor-history-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry___fr",
    path: "/fr/labor-history-entry",
    meta: indexrKOZFVqgrEMeta || {},
    component: () => import("/src/ckClient/pages/labor-history-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry___hi",
    path: "/hi/labor-history-entry",
    meta: indexrKOZFVqgrEMeta || {},
    component: () => import("/src/ckClient/pages/labor-history-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry___pt",
    path: "/pt/labor-history-entry",
    meta: indexrKOZFVqgrEMeta || {},
    component: () => import("/src/ckClient/pages/labor-history-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry-new___en",
    path: "/labor-history-entry/new",
    component: () => import("/src/ckClient/pages/labor-history-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry-new___es",
    path: "/es/labor-history-entry/new",
    component: () => import("/src/ckClient/pages/labor-history-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry-new___ar",
    path: "/ar/labor-history-entry/new",
    component: () => import("/src/ckClient/pages/labor-history-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry-new___ja",
    path: "/ja/labor-history-entry/new",
    component: () => import("/src/ckClient/pages/labor-history-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry-new___fr",
    path: "/fr/labor-history-entry/new",
    component: () => import("/src/ckClient/pages/labor-history-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry-new___hi",
    path: "/hi/labor-history-entry/new",
    component: () => import("/src/ckClient/pages/labor-history-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "labor-history-entry-new___pt",
    path: "/pt/labor-history-entry/new",
    component: () => import("/src/ckClient/pages/labor-history-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginfYRDvrTUKnMeta || {},
    component: () => import("/src/ckClient/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___es",
    path: "/es/login",
    meta: loginfYRDvrTUKnMeta || {},
    component: () => import("/src/ckClient/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___ar",
    path: "/ar/login",
    meta: loginfYRDvrTUKnMeta || {},
    component: () => import("/src/ckClient/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___ja",
    path: "/ja/login",
    meta: loginfYRDvrTUKnMeta || {},
    component: () => import("/src/ckClient/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___fr",
    path: "/fr/login",
    meta: loginfYRDvrTUKnMeta || {},
    component: () => import("/src/ckClient/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___hi",
    path: "/hi/login",
    meta: loginfYRDvrTUKnMeta || {},
    component: () => import("/src/ckClient/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___pt",
    path: "/pt/login",
    meta: loginfYRDvrTUKnMeta || {},
    component: () => import("/src/ckClient/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-cars-by-inspection___en",
    path: "/reporting/inspection/cars-by-inspection",
    meta: cars_45by_45inspection18ntwvEoLjMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/cars-by-inspection.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-cars-by-inspection___es",
    path: "/es/reporting/inspection/cars-by-inspection",
    meta: cars_45by_45inspection18ntwvEoLjMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/cars-by-inspection.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-cars-by-inspection___ar",
    path: "/ar/reporting/inspection/cars-by-inspection",
    meta: cars_45by_45inspection18ntwvEoLjMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/cars-by-inspection.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-cars-by-inspection___ja",
    path: "/ja/reporting/inspection/cars-by-inspection",
    meta: cars_45by_45inspection18ntwvEoLjMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/cars-by-inspection.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-cars-by-inspection___fr",
    path: "/fr/reporting/inspection/cars-by-inspection",
    meta: cars_45by_45inspection18ntwvEoLjMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/cars-by-inspection.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-cars-by-inspection___hi",
    path: "/hi/reporting/inspection/cars-by-inspection",
    meta: cars_45by_45inspection18ntwvEoLjMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/cars-by-inspection.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-cars-by-inspection___pt",
    path: "/pt/reporting/inspection/cars-by-inspection",
    meta: cars_45by_45inspection18ntwvEoLjMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/cars-by-inspection.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-category___en",
    path: "/reporting/inspection/events-by-category",
    meta: events_45by_45categorycLNkslKXMuMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-category.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-category___es",
    path: "/es/reporting/inspection/events-by-category",
    meta: events_45by_45categorycLNkslKXMuMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-category.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-category___ar",
    path: "/ar/reporting/inspection/events-by-category",
    meta: events_45by_45categorycLNkslKXMuMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-category.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-category___ja",
    path: "/ja/reporting/inspection/events-by-category",
    meta: events_45by_45categorycLNkslKXMuMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-category.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-category___fr",
    path: "/fr/reporting/inspection/events-by-category",
    meta: events_45by_45categorycLNkslKXMuMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-category.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-category___hi",
    path: "/hi/reporting/inspection/events-by-category",
    meta: events_45by_45categorycLNkslKXMuMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-category.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-category___pt",
    path: "/pt/reporting/inspection/events-by-category",
    meta: events_45by_45categorycLNkslKXMuMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-category.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-type___en",
    path: "/reporting/inspection/events-by-type",
    meta: events_45by_45typeOb1ReI7HBxMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-type.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-type___es",
    path: "/es/reporting/inspection/events-by-type",
    meta: events_45by_45typeOb1ReI7HBxMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-type.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-type___ar",
    path: "/ar/reporting/inspection/events-by-type",
    meta: events_45by_45typeOb1ReI7HBxMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-type.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-type___ja",
    path: "/ja/reporting/inspection/events-by-type",
    meta: events_45by_45typeOb1ReI7HBxMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-type.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-type___fr",
    path: "/fr/reporting/inspection/events-by-type",
    meta: events_45by_45typeOb1ReI7HBxMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-type.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-type___hi",
    path: "/hi/reporting/inspection/events-by-type",
    meta: events_45by_45typeOb1ReI7HBxMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-type.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-type___pt",
    path: "/pt/reporting/inspection/events-by-type",
    meta: events_45by_45typeOb1ReI7HBxMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-type.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-zone___en",
    path: "/reporting/inspection/events-by-zone",
    meta: events_45by_45zone0Ikw9pJYzfMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-zone.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-zone___es",
    path: "/es/reporting/inspection/events-by-zone",
    meta: events_45by_45zone0Ikw9pJYzfMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-zone.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-zone___ar",
    path: "/ar/reporting/inspection/events-by-zone",
    meta: events_45by_45zone0Ikw9pJYzfMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-zone.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-zone___ja",
    path: "/ja/reporting/inspection/events-by-zone",
    meta: events_45by_45zone0Ikw9pJYzfMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-zone.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-zone___fr",
    path: "/fr/reporting/inspection/events-by-zone",
    meta: events_45by_45zone0Ikw9pJYzfMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-zone.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-zone___hi",
    path: "/hi/reporting/inspection/events-by-zone",
    meta: events_45by_45zone0Ikw9pJYzfMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-zone.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events-by-zone___pt",
    path: "/pt/reporting/inspection/events-by-zone",
    meta: events_45by_45zone0Ikw9pJYzfMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events-by-zone.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events___en",
    path: "/reporting/inspection/events",
    meta: eventskhaA5iFJ8nMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events___es",
    path: "/es/reporting/inspection/events",
    meta: eventskhaA5iFJ8nMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events___ar",
    path: "/ar/reporting/inspection/events",
    meta: eventskhaA5iFJ8nMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events___ja",
    path: "/ja/reporting/inspection/events",
    meta: eventskhaA5iFJ8nMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events___fr",
    path: "/fr/reporting/inspection/events",
    meta: eventskhaA5iFJ8nMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events___hi",
    path: "/hi/reporting/inspection/events",
    meta: eventskhaA5iFJ8nMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events.vue").then(m => m.default || m)
  },
  {
    name: "reporting-inspection-events___pt",
    path: "/pt/reporting/inspection/events",
    meta: eventskhaA5iFJ8nMeta || {},
    component: () => import("/src/ckClient/pages/reporting/inspection/events.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-labor-history-forecast___en",
    path: "/reporting/labor/labor-history-forecast",
    meta: labor_45history_45forecastjGfOtlw7WkMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/labor-history-forecast.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-labor-history-forecast___es",
    path: "/es/reporting/labor/labor-history-forecast",
    meta: labor_45history_45forecastjGfOtlw7WkMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/labor-history-forecast.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-labor-history-forecast___ar",
    path: "/ar/reporting/labor/labor-history-forecast",
    meta: labor_45history_45forecastjGfOtlw7WkMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/labor-history-forecast.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-labor-history-forecast___ja",
    path: "/ja/reporting/labor/labor-history-forecast",
    meta: labor_45history_45forecastjGfOtlw7WkMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/labor-history-forecast.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-labor-history-forecast___fr",
    path: "/fr/reporting/labor/labor-history-forecast",
    meta: labor_45history_45forecastjGfOtlw7WkMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/labor-history-forecast.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-labor-history-forecast___hi",
    path: "/hi/reporting/labor/labor-history-forecast",
    meta: labor_45history_45forecastjGfOtlw7WkMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/labor-history-forecast.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-labor-history-forecast___pt",
    path: "/pt/reporting/labor/labor-history-forecast",
    meta: labor_45history_45forecastjGfOtlw7WkMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/labor-history-forecast.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-labor-history-report___en",
    path: "/reporting/labor/labor-history-report",
    meta: labor_45history_45reportfNq7O4AYcqMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/labor-history-report.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-labor-history-report___es",
    path: "/es/reporting/labor/labor-history-report",
    meta: labor_45history_45reportfNq7O4AYcqMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/labor-history-report.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-labor-history-report___ar",
    path: "/ar/reporting/labor/labor-history-report",
    meta: labor_45history_45reportfNq7O4AYcqMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/labor-history-report.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-labor-history-report___ja",
    path: "/ja/reporting/labor/labor-history-report",
    meta: labor_45history_45reportfNq7O4AYcqMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/labor-history-report.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-labor-history-report___fr",
    path: "/fr/reporting/labor/labor-history-report",
    meta: labor_45history_45reportfNq7O4AYcqMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/labor-history-report.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-labor-history-report___hi",
    path: "/hi/reporting/labor/labor-history-report",
    meta: labor_45history_45reportfNq7O4AYcqMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/labor-history-report.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-labor-history-report___pt",
    path: "/pt/reporting/labor/labor-history-report",
    meta: labor_45history_45reportfNq7O4AYcqMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/labor-history-report.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-total-resource-utilization___en",
    path: "/reporting/labor/total-resource-utilization",
    meta: total_45resource_45utilizationAimhmRNSpPMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/total-resource-utilization.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-total-resource-utilization___es",
    path: "/es/reporting/labor/total-resource-utilization",
    meta: total_45resource_45utilizationAimhmRNSpPMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/total-resource-utilization.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-total-resource-utilization___ar",
    path: "/ar/reporting/labor/total-resource-utilization",
    meta: total_45resource_45utilizationAimhmRNSpPMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/total-resource-utilization.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-total-resource-utilization___ja",
    path: "/ja/reporting/labor/total-resource-utilization",
    meta: total_45resource_45utilizationAimhmRNSpPMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/total-resource-utilization.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-total-resource-utilization___fr",
    path: "/fr/reporting/labor/total-resource-utilization",
    meta: total_45resource_45utilizationAimhmRNSpPMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/total-resource-utilization.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-total-resource-utilization___hi",
    path: "/hi/reporting/labor/total-resource-utilization",
    meta: total_45resource_45utilizationAimhmRNSpPMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/total-resource-utilization.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-total-resource-utilization___pt",
    path: "/pt/reporting/labor/total-resource-utilization",
    meta: total_45resource_45utilizationAimhmRNSpPMeta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/total-resource-utilization.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-worksheet-completion-percentage___en",
    path: "/reporting/labor/worksheet-completion-percentage",
    meta: worksheet_45completion_45percentage8hvYFo5pt5Meta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/worksheet-completion-percentage.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-worksheet-completion-percentage___es",
    path: "/es/reporting/labor/worksheet-completion-percentage",
    meta: worksheet_45completion_45percentage8hvYFo5pt5Meta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/worksheet-completion-percentage.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-worksheet-completion-percentage___ar",
    path: "/ar/reporting/labor/worksheet-completion-percentage",
    meta: worksheet_45completion_45percentage8hvYFo5pt5Meta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/worksheet-completion-percentage.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-worksheet-completion-percentage___ja",
    path: "/ja/reporting/labor/worksheet-completion-percentage",
    meta: worksheet_45completion_45percentage8hvYFo5pt5Meta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/worksheet-completion-percentage.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-worksheet-completion-percentage___fr",
    path: "/fr/reporting/labor/worksheet-completion-percentage",
    meta: worksheet_45completion_45percentage8hvYFo5pt5Meta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/worksheet-completion-percentage.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-worksheet-completion-percentage___hi",
    path: "/hi/reporting/labor/worksheet-completion-percentage",
    meta: worksheet_45completion_45percentage8hvYFo5pt5Meta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/worksheet-completion-percentage.vue").then(m => m.default || m)
  },
  {
    name: "reporting-labor-worksheet-completion-percentage___pt",
    path: "/pt/reporting/labor/worksheet-completion-percentage",
    meta: worksheet_45completion_45percentage8hvYFo5pt5Meta || {},
    component: () => import("/src/ckClient/pages/reporting/labor/worksheet-completion-percentage.vue").then(m => m.default || m)
  },
  {
    name: "reporting-training-employee-by-skill___en",
    path: "/reporting/training/employee-by-skill",
    meta: employee_45by_45skillRcPC8oSXcDMeta || {},
    component: () => import("/src/ckClient/pages/reporting/training/employee-by-skill.vue").then(m => m.default || m)
  },
  {
    name: "reporting-training-employee-by-skill___es",
    path: "/es/reporting/training/employee-by-skill",
    meta: employee_45by_45skillRcPC8oSXcDMeta || {},
    component: () => import("/src/ckClient/pages/reporting/training/employee-by-skill.vue").then(m => m.default || m)
  },
  {
    name: "reporting-training-employee-by-skill___ar",
    path: "/ar/reporting/training/employee-by-skill",
    meta: employee_45by_45skillRcPC8oSXcDMeta || {},
    component: () => import("/src/ckClient/pages/reporting/training/employee-by-skill.vue").then(m => m.default || m)
  },
  {
    name: "reporting-training-employee-by-skill___ja",
    path: "/ja/reporting/training/employee-by-skill",
    meta: employee_45by_45skillRcPC8oSXcDMeta || {},
    component: () => import("/src/ckClient/pages/reporting/training/employee-by-skill.vue").then(m => m.default || m)
  },
  {
    name: "reporting-training-employee-by-skill___fr",
    path: "/fr/reporting/training/employee-by-skill",
    meta: employee_45by_45skillRcPC8oSXcDMeta || {},
    component: () => import("/src/ckClient/pages/reporting/training/employee-by-skill.vue").then(m => m.default || m)
  },
  {
    name: "reporting-training-employee-by-skill___hi",
    path: "/hi/reporting/training/employee-by-skill",
    meta: employee_45by_45skillRcPC8oSXcDMeta || {},
    component: () => import("/src/ckClient/pages/reporting/training/employee-by-skill.vue").then(m => m.default || m)
  },
  {
    name: "reporting-training-employee-by-skill___pt",
    path: "/pt/reporting/training/employee-by-skill",
    meta: employee_45by_45skillRcPC8oSXcDMeta || {},
    component: () => import("/src/ckClient/pages/reporting/training/employee-by-skill.vue").then(m => m.default || m)
  },
  {
    name: "reporting-training-training-log___en",
    path: "/reporting/training/training-log",
    meta: training_45logNGVfqVam6JMeta || {},
    component: () => import("/src/ckClient/pages/reporting/training/training-log.vue").then(m => m.default || m)
  },
  {
    name: "reporting-training-training-log___es",
    path: "/es/reporting/training/training-log",
    meta: training_45logNGVfqVam6JMeta || {},
    component: () => import("/src/ckClient/pages/reporting/training/training-log.vue").then(m => m.default || m)
  },
  {
    name: "reporting-training-training-log___ar",
    path: "/ar/reporting/training/training-log",
    meta: training_45logNGVfqVam6JMeta || {},
    component: () => import("/src/ckClient/pages/reporting/training/training-log.vue").then(m => m.default || m)
  },
  {
    name: "reporting-training-training-log___ja",
    path: "/ja/reporting/training/training-log",
    meta: training_45logNGVfqVam6JMeta || {},
    component: () => import("/src/ckClient/pages/reporting/training/training-log.vue").then(m => m.default || m)
  },
  {
    name: "reporting-training-training-log___fr",
    path: "/fr/reporting/training/training-log",
    meta: training_45logNGVfqVam6JMeta || {},
    component: () => import("/src/ckClient/pages/reporting/training/training-log.vue").then(m => m.default || m)
  },
  {
    name: "reporting-training-training-log___hi",
    path: "/hi/reporting/training/training-log",
    meta: training_45logNGVfqVam6JMeta || {},
    component: () => import("/src/ckClient/pages/reporting/training/training-log.vue").then(m => m.default || m)
  },
  {
    name: "reporting-training-training-log___pt",
    path: "/pt/reporting/training/training-log",
    meta: training_45logNGVfqVam6JMeta || {},
    component: () => import("/src/ckClient/pages/reporting/training/training-log.vue").then(m => m.default || m)
  },
  {
    name: "settings-document-control-entry___en",
    path: "/settings/document-control-entry",
    meta: indexnDOqJMohUrMeta || {},
    component: () => import("/src/ckClient/pages/settings/document-control-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-document-control-entry___es",
    path: "/es/settings/document-control-entry",
    meta: indexnDOqJMohUrMeta || {},
    component: () => import("/src/ckClient/pages/settings/document-control-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-document-control-entry___ar",
    path: "/ar/settings/document-control-entry",
    meta: indexnDOqJMohUrMeta || {},
    component: () => import("/src/ckClient/pages/settings/document-control-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-document-control-entry___ja",
    path: "/ja/settings/document-control-entry",
    meta: indexnDOqJMohUrMeta || {},
    component: () => import("/src/ckClient/pages/settings/document-control-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-document-control-entry___fr",
    path: "/fr/settings/document-control-entry",
    meta: indexnDOqJMohUrMeta || {},
    component: () => import("/src/ckClient/pages/settings/document-control-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-document-control-entry___hi",
    path: "/hi/settings/document-control-entry",
    meta: indexnDOqJMohUrMeta || {},
    component: () => import("/src/ckClient/pages/settings/document-control-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-document-control-entry___pt",
    path: "/pt/settings/document-control-entry",
    meta: indexnDOqJMohUrMeta || {},
    component: () => import("/src/ckClient/pages/settings/document-control-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-default-assignments___en",
    path: "/settings/employees/default-assignments",
    meta: indexGbncg4g3QAMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/default-assignments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-default-assignments___es",
    path: "/es/settings/employees/default-assignments",
    meta: indexGbncg4g3QAMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/default-assignments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-default-assignments___ar",
    path: "/ar/settings/employees/default-assignments",
    meta: indexGbncg4g3QAMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/default-assignments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-default-assignments___ja",
    path: "/ja/settings/employees/default-assignments",
    meta: indexGbncg4g3QAMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/default-assignments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-default-assignments___fr",
    path: "/fr/settings/employees/default-assignments",
    meta: indexGbncg4g3QAMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/default-assignments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-default-assignments___hi",
    path: "/hi/settings/employees/default-assignments",
    meta: indexGbncg4g3QAMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/default-assignments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-default-assignments___pt",
    path: "/pt/settings/employees/default-assignments",
    meta: indexGbncg4g3QAMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/default-assignments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-departments___en",
    path: "/settings/employees/departments",
    meta: indexzahAeCU8R7Meta || {},
    component: () => import("/src/ckClient/pages/settings/employees/departments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-departments___es",
    path: "/es/settings/employees/departments",
    meta: indexzahAeCU8R7Meta || {},
    component: () => import("/src/ckClient/pages/settings/employees/departments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-departments___ar",
    path: "/ar/settings/employees/departments",
    meta: indexzahAeCU8R7Meta || {},
    component: () => import("/src/ckClient/pages/settings/employees/departments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-departments___ja",
    path: "/ja/settings/employees/departments",
    meta: indexzahAeCU8R7Meta || {},
    component: () => import("/src/ckClient/pages/settings/employees/departments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-departments___fr",
    path: "/fr/settings/employees/departments",
    meta: indexzahAeCU8R7Meta || {},
    component: () => import("/src/ckClient/pages/settings/employees/departments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-departments___hi",
    path: "/hi/settings/employees/departments",
    meta: indexzahAeCU8R7Meta || {},
    component: () => import("/src/ckClient/pages/settings/employees/departments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-departments___pt",
    path: "/pt/settings/employees/departments",
    meta: indexzahAeCU8R7Meta || {},
    component: () => import("/src/ckClient/pages/settings/employees/departments/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information-number___en",
    path: "/settings/employees/employee-information/:number()",
    meta: _91number_93CbTYBCVzGvMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information-number___es",
    path: "/es/settings/employees/employee-information/:number()",
    meta: _91number_93CbTYBCVzGvMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information-number___ar",
    path: "/ar/settings/employees/employee-information/:number()",
    meta: _91number_93CbTYBCVzGvMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information-number___ja",
    path: "/ja/settings/employees/employee-information/:number()",
    meta: _91number_93CbTYBCVzGvMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information-number___fr",
    path: "/fr/settings/employees/employee-information/:number()",
    meta: _91number_93CbTYBCVzGvMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information-number___hi",
    path: "/hi/settings/employees/employee-information/:number()",
    meta: _91number_93CbTYBCVzGvMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information-number___pt",
    path: "/pt/settings/employees/employee-information/:number()",
    meta: _91number_93CbTYBCVzGvMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information___en",
    path: "/settings/employees/employee-information",
    meta: indexq0phq9E0KMMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information___es",
    path: "/es/settings/employees/employee-information",
    meta: indexq0phq9E0KMMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information___ar",
    path: "/ar/settings/employees/employee-information",
    meta: indexq0phq9E0KMMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information___ja",
    path: "/ja/settings/employees/employee-information",
    meta: indexq0phq9E0KMMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information___fr",
    path: "/fr/settings/employees/employee-information",
    meta: indexq0phq9E0KMMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information___hi",
    path: "/hi/settings/employees/employee-information",
    meta: indexq0phq9E0KMMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information___pt",
    path: "/pt/settings/employees/employee-information",
    meta: indexq0phq9E0KMMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information-new___en",
    path: "/settings/employees/employee-information/new",
    meta: newciel8EzYqJMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information-new___es",
    path: "/es/settings/employees/employee-information/new",
    meta: newciel8EzYqJMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information-new___ar",
    path: "/ar/settings/employees/employee-information/new",
    meta: newciel8EzYqJMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information-new___ja",
    path: "/ja/settings/employees/employee-information/new",
    meta: newciel8EzYqJMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information-new___fr",
    path: "/fr/settings/employees/employee-information/new",
    meta: newciel8EzYqJMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information-new___hi",
    path: "/hi/settings/employees/employee-information/new",
    meta: newciel8EzYqJMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-employee-information-new___pt",
    path: "/pt/settings/employees/employee-information/new",
    meta: newciel8EzYqJMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/employee-information/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types-code___en",
    path: "/settings/employees/skill-types/:code()",
    meta: _91code_93R12ObDsSmLMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/[code].vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types-code___es",
    path: "/es/settings/employees/skill-types/:code()",
    meta: _91code_93R12ObDsSmLMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/[code].vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types-code___ar",
    path: "/ar/settings/employees/skill-types/:code()",
    meta: _91code_93R12ObDsSmLMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/[code].vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types-code___ja",
    path: "/ja/settings/employees/skill-types/:code()",
    meta: _91code_93R12ObDsSmLMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/[code].vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types-code___fr",
    path: "/fr/settings/employees/skill-types/:code()",
    meta: _91code_93R12ObDsSmLMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/[code].vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types-code___hi",
    path: "/hi/settings/employees/skill-types/:code()",
    meta: _91code_93R12ObDsSmLMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/[code].vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types-code___pt",
    path: "/pt/settings/employees/skill-types/:code()",
    meta: _91code_93R12ObDsSmLMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/[code].vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types___en",
    path: "/settings/employees/skill-types",
    meta: indextRY2w0H2lzMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types___es",
    path: "/es/settings/employees/skill-types",
    meta: indextRY2w0H2lzMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types___ar",
    path: "/ar/settings/employees/skill-types",
    meta: indextRY2w0H2lzMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types___ja",
    path: "/ja/settings/employees/skill-types",
    meta: indextRY2w0H2lzMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types___fr",
    path: "/fr/settings/employees/skill-types",
    meta: indextRY2w0H2lzMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types___hi",
    path: "/hi/settings/employees/skill-types",
    meta: indextRY2w0H2lzMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types___pt",
    path: "/pt/settings/employees/skill-types",
    meta: indextRY2w0H2lzMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types-new___en",
    path: "/settings/employees/skill-types/new",
    meta: newEiFmGBPOl8Meta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types-new___es",
    path: "/es/settings/employees/skill-types/new",
    meta: newEiFmGBPOl8Meta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types-new___ar",
    path: "/ar/settings/employees/skill-types/new",
    meta: newEiFmGBPOl8Meta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types-new___ja",
    path: "/ja/settings/employees/skill-types/new",
    meta: newEiFmGBPOl8Meta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types-new___fr",
    path: "/fr/settings/employees/skill-types/new",
    meta: newEiFmGBPOl8Meta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types-new___hi",
    path: "/hi/settings/employees/skill-types/new",
    meta: newEiFmGBPOl8Meta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-skill-types-new___pt",
    path: "/pt/settings/employees/skill-types/new",
    meta: newEiFmGBPOl8Meta || {},
    component: () => import("/src/ckClient/pages/settings/employees/skill-types/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-training-types___en",
    path: "/settings/employees/training-types",
    meta: indexp2UQWW1POuMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/training-types/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-training-types___es",
    path: "/es/settings/employees/training-types",
    meta: indexp2UQWW1POuMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/training-types/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-training-types___ar",
    path: "/ar/settings/employees/training-types",
    meta: indexp2UQWW1POuMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/training-types/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-training-types___ja",
    path: "/ja/settings/employees/training-types",
    meta: indexp2UQWW1POuMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/training-types/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-training-types___fr",
    path: "/fr/settings/employees/training-types",
    meta: indexp2UQWW1POuMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/training-types/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-training-types___hi",
    path: "/hi/settings/employees/training-types",
    meta: indexp2UQWW1POuMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/training-types/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-employees-training-types___pt",
    path: "/pt/settings/employees/training-types",
    meta: indexp2UQWW1POuMeta || {},
    component: () => import("/src/ckClient/pages/settings/employees/training-types/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-corporate-categories___en",
    path: "/settings/equipment-area-information/corporate-categories",
    meta: indexpMLgn4RNT4Meta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/corporate-categories/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-corporate-categories___es",
    path: "/es/settings/equipment-area-information/corporate-categories",
    meta: indexpMLgn4RNT4Meta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/corporate-categories/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-corporate-categories___ar",
    path: "/ar/settings/equipment-area-information/corporate-categories",
    meta: indexpMLgn4RNT4Meta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/corporate-categories/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-corporate-categories___ja",
    path: "/ja/settings/equipment-area-information/corporate-categories",
    meta: indexpMLgn4RNT4Meta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/corporate-categories/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-corporate-categories___fr",
    path: "/fr/settings/equipment-area-information/corporate-categories",
    meta: indexpMLgn4RNT4Meta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/corporate-categories/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-corporate-categories___hi",
    path: "/hi/settings/equipment-area-information/corporate-categories",
    meta: indexpMLgn4RNT4Meta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/corporate-categories/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-corporate-categories___pt",
    path: "/pt/settings/equipment-area-information/corporate-categories",
    meta: indexpMLgn4RNT4Meta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/corporate-categories/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas-number___en",
    path: "/settings/equipment-area-information/equipment-areas/:number()",
    meta: _91number_93m2pZSUcXULMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas-number___es",
    path: "/es/settings/equipment-area-information/equipment-areas/:number()",
    meta: _91number_93m2pZSUcXULMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas-number___ar",
    path: "/ar/settings/equipment-area-information/equipment-areas/:number()",
    meta: _91number_93m2pZSUcXULMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas-number___ja",
    path: "/ja/settings/equipment-area-information/equipment-areas/:number()",
    meta: _91number_93m2pZSUcXULMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas-number___fr",
    path: "/fr/settings/equipment-area-information/equipment-areas/:number()",
    meta: _91number_93m2pZSUcXULMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas-number___hi",
    path: "/hi/settings/equipment-area-information/equipment-areas/:number()",
    meta: _91number_93m2pZSUcXULMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas-number___pt",
    path: "/pt/settings/equipment-area-information/equipment-areas/:number()",
    meta: _91number_93m2pZSUcXULMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas___en",
    path: "/settings/equipment-area-information/equipment-areas",
    meta: indexD7luvKcuNIMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas___es",
    path: "/es/settings/equipment-area-information/equipment-areas",
    meta: indexD7luvKcuNIMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas___ar",
    path: "/ar/settings/equipment-area-information/equipment-areas",
    meta: indexD7luvKcuNIMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas___ja",
    path: "/ja/settings/equipment-area-information/equipment-areas",
    meta: indexD7luvKcuNIMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas___fr",
    path: "/fr/settings/equipment-area-information/equipment-areas",
    meta: indexD7luvKcuNIMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas___hi",
    path: "/hi/settings/equipment-area-information/equipment-areas",
    meta: indexD7luvKcuNIMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas___pt",
    path: "/pt/settings/equipment-area-information/equipment-areas",
    meta: indexD7luvKcuNIMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas-new___en",
    path: "/settings/equipment-area-information/equipment-areas/new",
    meta: new10dyxx8sYyMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas-new___es",
    path: "/es/settings/equipment-area-information/equipment-areas/new",
    meta: new10dyxx8sYyMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas-new___ar",
    path: "/ar/settings/equipment-area-information/equipment-areas/new",
    meta: new10dyxx8sYyMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas-new___ja",
    path: "/ja/settings/equipment-area-information/equipment-areas/new",
    meta: new10dyxx8sYyMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas-new___fr",
    path: "/fr/settings/equipment-area-information/equipment-areas/new",
    meta: new10dyxx8sYyMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas-new___hi",
    path: "/hi/settings/equipment-area-information/equipment-areas/new",
    meta: new10dyxx8sYyMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-equipment-areas-new___pt",
    path: "/pt/settings/equipment-area-information/equipment-areas/new",
    meta: new10dyxx8sYyMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/equipment-areas/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers-number___en",
    path: "/settings/equipment-area-information/manufacturers/:number()",
    meta: _91number_93LGxWw7P3XmMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers-number___es",
    path: "/es/settings/equipment-area-information/manufacturers/:number()",
    meta: _91number_93LGxWw7P3XmMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers-number___ar",
    path: "/ar/settings/equipment-area-information/manufacturers/:number()",
    meta: _91number_93LGxWw7P3XmMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers-number___ja",
    path: "/ja/settings/equipment-area-information/manufacturers/:number()",
    meta: _91number_93LGxWw7P3XmMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers-number___fr",
    path: "/fr/settings/equipment-area-information/manufacturers/:number()",
    meta: _91number_93LGxWw7P3XmMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers-number___hi",
    path: "/hi/settings/equipment-area-information/manufacturers/:number()",
    meta: _91number_93LGxWw7P3XmMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers-number___pt",
    path: "/pt/settings/equipment-area-information/manufacturers/:number()",
    meta: _91number_93LGxWw7P3XmMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers___en",
    path: "/settings/equipment-area-information/manufacturers",
    meta: index4TDeL6AQhHMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers___es",
    path: "/es/settings/equipment-area-information/manufacturers",
    meta: index4TDeL6AQhHMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers___ar",
    path: "/ar/settings/equipment-area-information/manufacturers",
    meta: index4TDeL6AQhHMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers___ja",
    path: "/ja/settings/equipment-area-information/manufacturers",
    meta: index4TDeL6AQhHMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers___fr",
    path: "/fr/settings/equipment-area-information/manufacturers",
    meta: index4TDeL6AQhHMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers___hi",
    path: "/hi/settings/equipment-area-information/manufacturers",
    meta: index4TDeL6AQhHMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers___pt",
    path: "/pt/settings/equipment-area-information/manufacturers",
    meta: index4TDeL6AQhHMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers-new___en",
    path: "/settings/equipment-area-information/manufacturers/new",
    meta: new59KvFIA6QTMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers-new___es",
    path: "/es/settings/equipment-area-information/manufacturers/new",
    meta: new59KvFIA6QTMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers-new___ar",
    path: "/ar/settings/equipment-area-information/manufacturers/new",
    meta: new59KvFIA6QTMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers-new___ja",
    path: "/ja/settings/equipment-area-information/manufacturers/new",
    meta: new59KvFIA6QTMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers-new___fr",
    path: "/fr/settings/equipment-area-information/manufacturers/new",
    meta: new59KvFIA6QTMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers-new___hi",
    path: "/hi/settings/equipment-area-information/manufacturers/new",
    meta: new59KvFIA6QTMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-manufacturers-new___pt",
    path: "/pt/settings/equipment-area-information/manufacturers/new",
    meta: new59KvFIA6QTMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/manufacturers/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-production-lines___en",
    path: "/settings/equipment-area-information/production-lines",
    meta: indexzyF9I0FzAYMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/production-lines/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-production-lines___es",
    path: "/es/settings/equipment-area-information/production-lines",
    meta: indexzyF9I0FzAYMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/production-lines/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-production-lines___ar",
    path: "/ar/settings/equipment-area-information/production-lines",
    meta: indexzyF9I0FzAYMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/production-lines/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-production-lines___ja",
    path: "/ja/settings/equipment-area-information/production-lines",
    meta: indexzyF9I0FzAYMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/production-lines/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-production-lines___fr",
    path: "/fr/settings/equipment-area-information/production-lines",
    meta: indexzyF9I0FzAYMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/production-lines/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-production-lines___hi",
    path: "/hi/settings/equipment-area-information/production-lines",
    meta: indexzyF9I0FzAYMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/production-lines/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-production-lines___pt",
    path: "/pt/settings/equipment-area-information/production-lines",
    meta: indexzyF9I0FzAYMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/production-lines/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-work-groups___en",
    path: "/settings/equipment-area-information/work-groups",
    meta: indexBHr2yMvwasMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/work-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-work-groups___es",
    path: "/es/settings/equipment-area-information/work-groups",
    meta: indexBHr2yMvwasMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/work-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-work-groups___ar",
    path: "/ar/settings/equipment-area-information/work-groups",
    meta: indexBHr2yMvwasMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/work-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-work-groups___ja",
    path: "/ja/settings/equipment-area-information/work-groups",
    meta: indexBHr2yMvwasMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/work-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-work-groups___fr",
    path: "/fr/settings/equipment-area-information/work-groups",
    meta: indexBHr2yMvwasMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/work-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-work-groups___hi",
    path: "/hi/settings/equipment-area-information/work-groups",
    meta: indexBHr2yMvwasMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/work-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-equipment-area-information-work-groups___pt",
    path: "/pt/settings/equipment-area-information/work-groups",
    meta: indexBHr2yMvwasMeta || {},
    component: () => import("/src/ckClient/pages/settings/equipment-area-information/work-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-category-entry___en",
    path: "/settings/inspections/inspection-category-entry",
    meta: indexWyXG8xHFCkMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-category-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-category-entry___es",
    path: "/es/settings/inspections/inspection-category-entry",
    meta: indexWyXG8xHFCkMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-category-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-category-entry___ar",
    path: "/ar/settings/inspections/inspection-category-entry",
    meta: indexWyXG8xHFCkMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-category-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-category-entry___ja",
    path: "/ja/settings/inspections/inspection-category-entry",
    meta: indexWyXG8xHFCkMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-category-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-category-entry___fr",
    path: "/fr/settings/inspections/inspection-category-entry",
    meta: indexWyXG8xHFCkMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-category-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-category-entry___hi",
    path: "/hi/settings/inspections/inspection-category-entry",
    meta: indexWyXG8xHFCkMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-category-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-category-entry___pt",
    path: "/pt/settings/inspections/inspection-category-entry",
    meta: indexWyXG8xHFCkMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-category-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry-number___en",
    path: "/settings/inspections/inspection-type-entry/:number()",
    meta: _91number_93O23IeWQwoCMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry-number___es",
    path: "/es/settings/inspections/inspection-type-entry/:number()",
    meta: _91number_93O23IeWQwoCMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry-number___ar",
    path: "/ar/settings/inspections/inspection-type-entry/:number()",
    meta: _91number_93O23IeWQwoCMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry-number___ja",
    path: "/ja/settings/inspections/inspection-type-entry/:number()",
    meta: _91number_93O23IeWQwoCMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry-number___fr",
    path: "/fr/settings/inspections/inspection-type-entry/:number()",
    meta: _91number_93O23IeWQwoCMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry-number___hi",
    path: "/hi/settings/inspections/inspection-type-entry/:number()",
    meta: _91number_93O23IeWQwoCMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry-number___pt",
    path: "/pt/settings/inspections/inspection-type-entry/:number()",
    meta: _91number_93O23IeWQwoCMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/[number].vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry___en",
    path: "/settings/inspections/inspection-type-entry",
    meta: index0p3muywXbsMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry___es",
    path: "/es/settings/inspections/inspection-type-entry",
    meta: index0p3muywXbsMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry___ar",
    path: "/ar/settings/inspections/inspection-type-entry",
    meta: index0p3muywXbsMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry___ja",
    path: "/ja/settings/inspections/inspection-type-entry",
    meta: index0p3muywXbsMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry___fr",
    path: "/fr/settings/inspections/inspection-type-entry",
    meta: index0p3muywXbsMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry___hi",
    path: "/hi/settings/inspections/inspection-type-entry",
    meta: index0p3muywXbsMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry___pt",
    path: "/pt/settings/inspections/inspection-type-entry",
    meta: index0p3muywXbsMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry-new___en",
    path: "/settings/inspections/inspection-type-entry/new",
    meta: newOuPAuEFtayMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry-new___es",
    path: "/es/settings/inspections/inspection-type-entry/new",
    meta: newOuPAuEFtayMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry-new___ar",
    path: "/ar/settings/inspections/inspection-type-entry/new",
    meta: newOuPAuEFtayMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry-new___ja",
    path: "/ja/settings/inspections/inspection-type-entry/new",
    meta: newOuPAuEFtayMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry-new___fr",
    path: "/fr/settings/inspections/inspection-type-entry/new",
    meta: newOuPAuEFtayMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry-new___hi",
    path: "/hi/settings/inspections/inspection-type-entry/new",
    meta: newOuPAuEFtayMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-type-entry-new___pt",
    path: "/pt/settings/inspections/inspection-type-entry/new",
    meta: newOuPAuEFtayMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-type-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-zone-entry___en",
    path: "/settings/inspections/inspection-zone-entry",
    meta: indexnFDiaQR3fLMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-zone-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-zone-entry___es",
    path: "/es/settings/inspections/inspection-zone-entry",
    meta: indexnFDiaQR3fLMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-zone-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-zone-entry___ar",
    path: "/ar/settings/inspections/inspection-zone-entry",
    meta: indexnFDiaQR3fLMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-zone-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-zone-entry___ja",
    path: "/ja/settings/inspections/inspection-zone-entry",
    meta: indexnFDiaQR3fLMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-zone-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-zone-entry___fr",
    path: "/fr/settings/inspections/inspection-zone-entry",
    meta: indexnFDiaQR3fLMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-zone-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-zone-entry___hi",
    path: "/hi/settings/inspections/inspection-zone-entry",
    meta: indexnFDiaQR3fLMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-zone-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-inspections-inspection-zone-entry___pt",
    path: "/pt/settings/inspections/inspection-zone-entry",
    meta: indexnFDiaQR3fLMeta || {},
    component: () => import("/src/ckClient/pages/settings/inspections/inspection-zone-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry-code___en",
    path: "/settings/material-entry/:code()",
    meta: _91code_93Qb4G37p48DMeta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/[code].vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry-code___es",
    path: "/es/settings/material-entry/:code()",
    meta: _91code_93Qb4G37p48DMeta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/[code].vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry-code___ar",
    path: "/ar/settings/material-entry/:code()",
    meta: _91code_93Qb4G37p48DMeta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/[code].vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry-code___ja",
    path: "/ja/settings/material-entry/:code()",
    meta: _91code_93Qb4G37p48DMeta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/[code].vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry-code___fr",
    path: "/fr/settings/material-entry/:code()",
    meta: _91code_93Qb4G37p48DMeta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/[code].vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry-code___hi",
    path: "/hi/settings/material-entry/:code()",
    meta: _91code_93Qb4G37p48DMeta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/[code].vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry-code___pt",
    path: "/pt/settings/material-entry/:code()",
    meta: _91code_93Qb4G37p48DMeta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/[code].vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry___en",
    path: "/settings/material-entry",
    meta: indexRHe1CBF1MZMeta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry___es",
    path: "/es/settings/material-entry",
    meta: indexRHe1CBF1MZMeta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry___ar",
    path: "/ar/settings/material-entry",
    meta: indexRHe1CBF1MZMeta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry___ja",
    path: "/ja/settings/material-entry",
    meta: indexRHe1CBF1MZMeta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry___fr",
    path: "/fr/settings/material-entry",
    meta: indexRHe1CBF1MZMeta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry___hi",
    path: "/hi/settings/material-entry",
    meta: indexRHe1CBF1MZMeta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry___pt",
    path: "/pt/settings/material-entry",
    meta: indexRHe1CBF1MZMeta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry-new___en",
    path: "/settings/material-entry/new",
    meta: newAsRNkPDHU2Meta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry-new___es",
    path: "/es/settings/material-entry/new",
    meta: newAsRNkPDHU2Meta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry-new___ar",
    path: "/ar/settings/material-entry/new",
    meta: newAsRNkPDHU2Meta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry-new___ja",
    path: "/ja/settings/material-entry/new",
    meta: newAsRNkPDHU2Meta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry-new___fr",
    path: "/fr/settings/material-entry/new",
    meta: newAsRNkPDHU2Meta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry-new___hi",
    path: "/hi/settings/material-entry/new",
    meta: newAsRNkPDHU2Meta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-material-entry-new___pt",
    path: "/pt/settings/material-entry/new",
    meta: newAsRNkPDHU2Meta || {},
    component: () => import("/src/ckClient/pages/settings/material-entry/new.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-daily-duties___en",
    path: "/task-scheduling/daily-duties",
    meta: indexNzj4KqZ03dMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/daily-duties/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-daily-duties___es",
    path: "/es/task-scheduling/daily-duties",
    meta: indexNzj4KqZ03dMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/daily-duties/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-daily-duties___ar",
    path: "/ar/task-scheduling/daily-duties",
    meta: indexNzj4KqZ03dMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/daily-duties/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-daily-duties___ja",
    path: "/ja/task-scheduling/daily-duties",
    meta: indexNzj4KqZ03dMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/daily-duties/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-daily-duties___fr",
    path: "/fr/task-scheduling/daily-duties",
    meta: indexNzj4KqZ03dMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/daily-duties/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-daily-duties___hi",
    path: "/hi/task-scheduling/daily-duties",
    meta: indexNzj4KqZ03dMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/daily-duties/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-daily-duties___pt",
    path: "/pt/task-scheduling/daily-duties",
    meta: indexNzj4KqZ03dMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/daily-duties/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-global-tasks___en",
    path: "/task-scheduling/global-tasks",
    meta: index3nDpb6uWd0Meta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/global-tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-global-tasks___es",
    path: "/es/task-scheduling/global-tasks",
    meta: index3nDpb6uWd0Meta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/global-tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-global-tasks___ar",
    path: "/ar/task-scheduling/global-tasks",
    meta: index3nDpb6uWd0Meta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/global-tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-global-tasks___ja",
    path: "/ja/task-scheduling/global-tasks",
    meta: index3nDpb6uWd0Meta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/global-tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-global-tasks___fr",
    path: "/fr/task-scheduling/global-tasks",
    meta: index3nDpb6uWd0Meta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/global-tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-global-tasks___hi",
    path: "/hi/task-scheduling/global-tasks",
    meta: index3nDpb6uWd0Meta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/global-tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-global-tasks___pt",
    path: "/pt/task-scheduling/global-tasks",
    meta: index3nDpb6uWd0Meta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/global-tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number___en",
    path: "/task-scheduling/task-lists/:number()",
    meta: indexVkyLDT7F3EMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number___es",
    path: "/es/task-scheduling/task-lists/:number()",
    meta: indexVkyLDT7F3EMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number___ar",
    path: "/ar/task-scheduling/task-lists/:number()",
    meta: indexVkyLDT7F3EMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number___ja",
    path: "/ja/task-scheduling/task-lists/:number()",
    meta: indexVkyLDT7F3EMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number___fr",
    path: "/fr/task-scheduling/task-lists/:number()",
    meta: indexVkyLDT7F3EMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number___hi",
    path: "/hi/task-scheduling/task-lists/:number()",
    meta: indexVkyLDT7F3EMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number___pt",
    path: "/pt/task-scheduling/task-lists/:number()",
    meta: indexVkyLDT7F3EMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-libraries___en",
    path: "/task-scheduling/task-lists/:number()/libraries",
    meta: librariesAmBZuVrruRMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/libraries.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-libraries___es",
    path: "/es/task-scheduling/task-lists/:number()/libraries",
    meta: librariesAmBZuVrruRMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/libraries.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-libraries___ar",
    path: "/ar/task-scheduling/task-lists/:number()/libraries",
    meta: librariesAmBZuVrruRMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/libraries.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-libraries___ja",
    path: "/ja/task-scheduling/task-lists/:number()/libraries",
    meta: librariesAmBZuVrruRMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/libraries.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-libraries___fr",
    path: "/fr/task-scheduling/task-lists/:number()/libraries",
    meta: librariesAmBZuVrruRMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/libraries.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-libraries___hi",
    path: "/hi/task-scheduling/task-lists/:number()/libraries",
    meta: librariesAmBZuVrruRMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/libraries.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-libraries___pt",
    path: "/pt/task-scheduling/task-lists/:number()/libraries",
    meta: librariesAmBZuVrruRMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/libraries.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-task-task___en",
    path: "/task-scheduling/task-lists/:number()/task/:task()",
    meta: _91task_937xU3l80XcbMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/task/[task].vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-task-task___es",
    path: "/es/task-scheduling/task-lists/:number()/task/:task()",
    meta: _91task_937xU3l80XcbMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/task/[task].vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-task-task___ar",
    path: "/ar/task-scheduling/task-lists/:number()/task/:task()",
    meta: _91task_937xU3l80XcbMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/task/[task].vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-task-task___ja",
    path: "/ja/task-scheduling/task-lists/:number()/task/:task()",
    meta: _91task_937xU3l80XcbMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/task/[task].vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-task-task___fr",
    path: "/fr/task-scheduling/task-lists/:number()/task/:task()",
    meta: _91task_937xU3l80XcbMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/task/[task].vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-task-task___hi",
    path: "/hi/task-scheduling/task-lists/:number()/task/:task()",
    meta: _91task_937xU3l80XcbMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/task/[task].vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-task-task___pt",
    path: "/pt/task-scheduling/task-lists/:number()/task/:task()",
    meta: _91task_937xU3l80XcbMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/task/[task].vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-task-new___en",
    path: "/task-scheduling/task-lists/:number()/task/new",
    meta: newjMxpxklyffMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/task/new.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-task-new___es",
    path: "/es/task-scheduling/task-lists/:number()/task/new",
    meta: newjMxpxklyffMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/task/new.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-task-new___ar",
    path: "/ar/task-scheduling/task-lists/:number()/task/new",
    meta: newjMxpxklyffMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/task/new.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-task-new___ja",
    path: "/ja/task-scheduling/task-lists/:number()/task/new",
    meta: newjMxpxklyffMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/task/new.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-task-new___fr",
    path: "/fr/task-scheduling/task-lists/:number()/task/new",
    meta: newjMxpxklyffMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/task/new.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-task-new___hi",
    path: "/hi/task-scheduling/task-lists/:number()/task/new",
    meta: newjMxpxklyffMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/task/new.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists-number-task-new___pt",
    path: "/pt/task-scheduling/task-lists/:number()/task/new",
    meta: newjMxpxklyffMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/[number]/task/new.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists___en",
    path: "/task-scheduling/task-lists",
    meta: indexJiYUzboxjMMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists___es",
    path: "/es/task-scheduling/task-lists",
    meta: indexJiYUzboxjMMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists___ar",
    path: "/ar/task-scheduling/task-lists",
    meta: indexJiYUzboxjMMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists___ja",
    path: "/ja/task-scheduling/task-lists",
    meta: indexJiYUzboxjMMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists___fr",
    path: "/fr/task-scheduling/task-lists",
    meta: indexJiYUzboxjMMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists___hi",
    path: "/hi/task-scheduling/task-lists",
    meta: indexJiYUzboxjMMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/index.vue").then(m => m.default || m)
  },
  {
    name: "task-scheduling-task-lists___pt",
    path: "/pt/task-scheduling/task-lists",
    meta: indexJiYUzboxjMMeta || {},
    component: () => import("/src/ckClient/pages/task-scheduling/task-lists/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-assign-workpacks___en",
    path: "/workpacks/assign-workpacks",
    meta: index8W5gjmVDfmMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/assign-workpacks/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-assign-workpacks___es",
    path: "/es/workpacks/assign-workpacks",
    meta: index8W5gjmVDfmMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/assign-workpacks/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-assign-workpacks___ar",
    path: "/ar/workpacks/assign-workpacks",
    meta: index8W5gjmVDfmMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/assign-workpacks/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-assign-workpacks___ja",
    path: "/ja/workpacks/assign-workpacks",
    meta: index8W5gjmVDfmMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/assign-workpacks/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-assign-workpacks___fr",
    path: "/fr/workpacks/assign-workpacks",
    meta: index8W5gjmVDfmMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/assign-workpacks/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-assign-workpacks___hi",
    path: "/hi/workpacks/assign-workpacks",
    meta: index8W5gjmVDfmMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/assign-workpacks/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-assign-workpacks___pt",
    path: "/pt/workpacks/assign-workpacks",
    meta: index8W5gjmVDfmMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/assign-workpacks/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-close-workpack-week___en",
    path: "/workpacks/close-workpack-week",
    meta: indextHVhFo5sgnMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/close-workpack-week/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-close-workpack-week___es",
    path: "/es/workpacks/close-workpack-week",
    meta: indextHVhFo5sgnMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/close-workpack-week/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-close-workpack-week___ar",
    path: "/ar/workpacks/close-workpack-week",
    meta: indextHVhFo5sgnMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/close-workpack-week/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-close-workpack-week___ja",
    path: "/ja/workpacks/close-workpack-week",
    meta: indextHVhFo5sgnMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/close-workpack-week/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-close-workpack-week___fr",
    path: "/fr/workpacks/close-workpack-week",
    meta: indextHVhFo5sgnMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/close-workpack-week/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-close-workpack-week___hi",
    path: "/hi/workpacks/close-workpack-week",
    meta: indextHVhFo5sgnMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/close-workpack-week/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-close-workpack-week___pt",
    path: "/pt/workpacks/close-workpack-week",
    meta: indextHVhFo5sgnMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/close-workpack-week/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-list-workpacks___en",
    path: "/workpacks/list-workpacks",
    meta: indexWG49FPlFzkMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/list-workpacks/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-list-workpacks___es",
    path: "/es/workpacks/list-workpacks",
    meta: indexWG49FPlFzkMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/list-workpacks/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-list-workpacks___ar",
    path: "/ar/workpacks/list-workpacks",
    meta: indexWG49FPlFzkMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/list-workpacks/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-list-workpacks___ja",
    path: "/ja/workpacks/list-workpacks",
    meta: indexWG49FPlFzkMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/list-workpacks/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-list-workpacks___fr",
    path: "/fr/workpacks/list-workpacks",
    meta: indexWG49FPlFzkMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/list-workpacks/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-list-workpacks___hi",
    path: "/hi/workpacks/list-workpacks",
    meta: indexWG49FPlFzkMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/list-workpacks/index.vue").then(m => m.default || m)
  },
  {
    name: "workpacks-list-workpacks___pt",
    path: "/pt/workpacks/list-workpacks",
    meta: indexWG49FPlFzkMeta || {},
    component: () => import("/src/ckClient/pages/workpacks/list-workpacks/index.vue").then(m => m.default || m)
  }
]