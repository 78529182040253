export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive<HTMLElement, (e: MouseEvent | DragEvent) => void>("mouse-coords", {
    created(el, binding) {
      el.addEventListener("mousemove", binding.value);
      el.addEventListener("dragover", binding.value);
    },
    unmounted(el, binding) {
      el.removeEventListener("mousemove", binding.value);
      el.removeEventListener("dragover", binding.value);
    },
  });
});
