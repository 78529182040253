<script setup lang="ts">
import { useEquipmentLookupListStore } from "~/store/lookup-lists.ts";
import { useCurrentPlant, usePlantListStore } from "~/store/plant-state.ts";
import { useProvideLangDir } from "~/composables/provided-state.ts";
import { startPersistentNotificationInitializer } from "~/store/global-notification.ts";

const head = useLocaleHead({ addDirAttribute: true, addSeoAttributes: true });

const directionRef = computed<"rtl" | "ltr">(() => head.value.htmlAttrs?.dir ?? "ltr");

useProvideLangDir(directionRef);

const plantListStore = usePlantListStore();

const { $auth } = useNuxtApp();
const isAuthed = await $auth.isAuthed();
await plantListStore.check();

// Since we need to call this when we know that we have reloaded the page, this seems like a good place to do it.
startPersistentNotificationInitializer();
const PlantData = useCurrentPlant();

if (isAuthed && PlantData.data?.plantId) {
  const equipmentLookupListStore = useEquipmentLookupListStore();
  equipmentLookupListStore.fetch();
}
</script>
<template>
  <Html v-bind="head.htmlAttrs" />
  <div>
    <LoadingGlobalPage />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <GlobalNotificationBar />
  </div>
</template>
<style scoped lang="postcss">
/**
intentionally left blank
 */
</style>
<style lang="postcss">
* {
  direction: v-bind("directionRef");
}
</style>
