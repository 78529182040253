import cloneDeep from "lodash-es/cloneDeep";
import { isString } from "assertate";
/**
 * Internal Tracking of all generated UIDs, so we don't get accidental collision.
 * @private
 */
const _UIDS: Set<string> = new Set<string>([""]);

/**
 * Generate a new Unique ID string.
 * @param [prefix] - Will prefix the generated uid with `${prefix}-` if supplied
 */
export function getUID(prefix?: string): string {
  let uid: string = "";
  while (_UIDS.has(uid)) {
    // User browser builtin
    if (window.isSecureContext) {
      uid = crypto.randomUUID();
    } else {
      // if browser builtin isn't available, then use our own thing
      uid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r: number = (Math.random() * 16) | 0,
          v: number = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }
    if (prefix) {
      uid = `${prefix}-${uid}`;
    }
  }
  _UIDS.add(uid);
  return uid;
}

/**
 * Will apply a UID to an object at the provided key
 * @param data - the object to have a UID applied to
 * @param [key='uid'] - the property name to be used
 * @param [prefix] - an optional prefix for the UID.
 */
export function applyUID(data: Record<string, unknown>, key: string = "uid", prefix?: string): Record<string, unknown> {
  // if the uid isn't defined, OR if it is defined, but isn't in the global registry.
  if (typeof data[key] === "undefined" || (isString(data[key]) && !_UIDS.has(<string>data[key]))) {
    data[key] = getUID(prefix);
  }

  return data;
}

/**
 * Will remove a UID on an object at the provided key and release it from the set
 * @param data - the object to have a UID applied to
 * @param [key='uid'] - the property name to be used
 */
export function removeUID(data: Record<string, unknown>, key: string = "uid"): Record<string, unknown> {
  const uid = data[key];
  if (uid) {
    delete data[key];
  }
  return data;
}

/**
 * Maps over an array of objects and returns a new array with UIDs applied.
 * @param data - the array of objects to be mapped over
 * @param [key='uid'] - the property name to be used
 * @param [prefix] - optional prefix for the UID
 */
export function mapApplyUID(
  data: Record<string, unknown>[],
  key: string = "uid",
  prefix?: string,
): Record<string, unknown>[] {
  return data.map((el: Record<string, unknown>): Record<string, unknown> => {
    return applyUID(el, key, prefix);
  });
}

/**
 * Maps over an array of objects and returns a new array with UIDs removed.
 * @param data - the array of objects to be mapped over
 * @param [key='uid'] - the property name to be used
 */
export function mapRemoveUID(data: Record<string, unknown>[], key: string = "uid"): Record<string, unknown>[] {
  return data.map((el: Record<string, unknown>): Record<string, unknown> => {
    return removeUID(el, key);
  });
}

/**
 * Deep clones and maps over an array of objects and returns a new array with UIDs applied.
 * @param data - the array of objects to be mapped over
 * @param [key='uid'] - the property name to be used
 * @param [prefix] - optional prefix for the UID
 */
export function cloneMapApplyUID(
  data: Record<string, unknown>[],
  key: string = "uid",
  prefix?: string,
): Record<string, unknown>[] {
  return mapApplyUID(cloneDeep(data), key, prefix);
}

/**
 * Deep clones and maps over an array of objects and returns a new array with UIDs removed.
 * @param data - the array of objects to be mapped over
 * @param [key='uid'] - the property name to be used
 */
export function cloneMapRemoveUID(data: Record<string, unknown>[], key: string = "uid"): Record<string, unknown>[] {
  return mapRemoveUID(cloneDeep(data), key);
}

/**
 * This is why you don't use index as a key, now we have to commit crimes.
 */
const MAGIC_KEY = Symbol("magicKey");
export function getMagicKey<T extends Record<string, unknown> & { [MAGIC_KEY]?: string }>(record: T): string {
  if (Object.hasOwn(record, MAGIC_KEY) && isString(record[MAGIC_KEY])) return record[MAGIC_KEY];
  Object.defineProperty(record, MAGIC_KEY, {
    value: getUID(),
    configurable: false,
    enumerable: false,
    writable: false,
  });
  return <string>record[MAGIC_KEY];
}
