export type * from "~/connectables/fetch/taskListManagement.ts";

import {
  deleteDdit,
  getDditList,
  createDdit,
  updateDdit,
  getDditReport,
  updateDditReport,
  getGpitList,
  createGpit,
  getLibraryList,
  getLibrary,
  createLibrary,
  updateLibrary,
  deleteLibrary,
  getTaskList,
  taskListCheckLockStatus,
  taskListClearLockedStatus,
  taskListSetLocked,
  updateTaskList,
} from "~/connectables/fetch/taskListManagement.ts";
import { useLocalFetch } from "~/composables/apiHandler.ts";
import { customFetchAcceptHeaderOpts } from "~/composables/customFetch.ts";

export enum CalculateMethodOpts {
  MasterSchedule = "M",
  LastDone = "L",
}

// region `/api/ddit`
export const useDailyDuties = () => useLocalFetch("getDditList", getDditList);

export const useCreateDailyDuty = () => useLocalFetch("createDdit", createDdit);

export const useUpdateDailyDuty = () => useLocalFetch("updateDdit", updateDdit);

export const useDeleteDailyDuty = () => useLocalFetch("deleteDdit", deleteDdit);

export const useGetDditReport = () =>
  useLocalFetch("getDditReport", getDditReport, customFetchAcceptHeaderOpts("application/pdf"));

export const useUpdateDditReport = () =>
  useLocalFetch("updateDditReport", updateDditReport, customFetchAcceptHeaderOpts("application/pdf"));
// endregion

// region `/api/gpit`
export const useGetGpitList = () => useLocalFetch("getGpitList", getGpitList);

export const useCreateGpit = () => useLocalFetch("createGpit", createGpit);
// endregion

// region `/api/librarymanagement`
export const useGetLibraryList = () => useLocalFetch("getLibraryList", getLibraryList);

export const useGetLibrary = () => useLocalFetch("getLibrary", getLibrary);

export const useCreateLibrary = () => useLocalFetch("createLibrary", createLibrary);

export const useUpdateLibrary = () => useLocalFetch("updateLibrary", updateLibrary);

export const useDeleteLibrary = () => useLocalFetch("deleteLibrary", deleteLibrary);
// endregion

// region `/api/tasklistmanagement`
export const useGetTaskList = () => useLocalFetch("getTaskList", getTaskList);

export const useTaskListCheckLockStatus = () => useLocalFetch("taskListCheckLockStatus", taskListCheckLockStatus);

export const useTaskListClearLockedStatus = () => useLocalFetch("taskListClearLockedStatus", taskListClearLockedStatus);

export const useTaskListSetLocked = () => useLocalFetch("taskListSetLocked", taskListSetLocked);

export const useUpdateTaskList = () => useLocalFetch("updateTaskList", updateTaskList);
// endregion
