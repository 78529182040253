import { ref } from "vue";
import { MenuNames } from "~/utils/navigationMenuData";

export const usePiniaCurrentMenu = defineStore("currentMenu", () => {
  const route = useRoute();
  const currentMenu = ref<MenuNames>(route.meta.menu || MenuNames.Main);
  const update = (val: MenuNames) => {
    currentMenu.value = val;
  };
  const value = readonly(currentMenu);
  return { update, value };
});
