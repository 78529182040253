/**
 * This function should only be used when needing to provide a one click download button rather than a 2 step
 * "generate download link" button.
 *
 * Typically, most most files that can be downloaded are able to be accessed via a standard `GET` request without
 * authentication. However, as many of the files we have are stored in the database for the current client, we cannot
 * programmatically know which client to choose without authentication. With this in mind, we have to manually fetch the
 * binary data from the API using an authenticated fetch call.
 *
 * In the event you have already downloaded the file in question (example: you are already displaying the full version of
 * an image and you wish to provide a download button) please use an anchor tag with a download attribute and the href
 * being the `URL.createObjectURL` of the previously downloaded blob.
 *
 * In the event that you wish to download a generated file, or a file that is not been displayed on a page yet
 * (examples: a full size image of a displayed thumbnail, a PDF, an excel spreadsheet), please be sure to make the
 * correctly authenticated fetch call for the file you are requesting, and then pass the subsequent Blob data into this
 * function.
 *
 * @param blob
 * @param fileName
 * @param options
 */
export function downloadBlob(blob: Blob | string, fileName: string = "download", options?: BlobPropertyBag) {
  const file = new Blob([blob], options);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none !important";
  const url = URL.createObjectURL(file);
  a.href = url;
  a.download = fileName;
  a.click();
  setTimeout(() => {
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 5000);
}

export function createBlobFromFile(file: File | Blob, type: string) {
  return new Blob([file], { type });
}

export function createBlobFromPdf(file: File | Blob) {
  return createBlobFromFile(file, "application/pdf");
}
