import type { NuxtApp } from "#app";

/**
 * @param nuxtApp - you can provide an instance of nuxtApp in the event you need to call this outside of setup.
 */
export function useReportError(nuxtApp?: NuxtApp) {
  const { $sentryAddBreadcrumb, $sentryCaptureException, $sentrySetContext, $sentrySetTag, $sentrySetUser } =
    nuxtApp ?? useNuxtApp();

  return {
    addBreadcrumb: $sentryAddBreadcrumb,
    captureException: $sentryCaptureException,
    setContext: $sentrySetContext,
    setTag: $sentrySetTag,
    setUser: $sentrySetUser,
  };
}
