import { isNull, isNumber, isString, isUndefined } from "assertate";
import type { ComputedRef } from "vue";

export type PresortOptions<T> = {
  numericKey: keyof T;
  alphaKey: keyof T;
};

export type SortKeyType = "alpha" | "numeric";
export type SortReverseType = "alphaReverse" | "numericReverse";
export type SortType = SortKeyType | SortReverseType;

export function useAlphaNumericSortType<T_Data extends Record<string, unknown>>(
  alphaKey: keyof T_Data,
  numericKey: keyof T_Data,
) {
  const sortType = ref<SortType>("numeric"); // ['alpha', 'alphaReverse', 'numeric', 'numericReverse']

  const handleSortClick = (t: SortType) => {
    if (sortType?.value === t && sortType?.value?.includes("Reverse")) {
      sortType.value = t.replace("Reverse", "") as SortKeyType;
    } else if (sortType?.value === t) {
      sortType.value = `${t}Reverse` as SortReverseType;
    } else {
      sortType.value = t;
    }
  };
  const sortByKey = (aItem: T_Data, bItem: T_Data, keyType?: SortType) => {
    keyType = keyType ?? sortType.value;
    const isReverse = keyType.includes("Reverse");
    const keyName = keyType === "alpha" ? alphaKey : numericKey;
    const a = aItem[keyName];
    const b = bItem[keyName];
    if (isNull(a) || isNull(b) || isUndefined(a) || isUndefined(b)) return 0;
    if (a < b) {
      return isReverse ? 1 : -1;
    }
    if (a > b) {
      return isReverse ? -1 : 1;
    }
    return 0;
  };
  return {
    sortType,
    handleSortClick,
    sortByKey,
  };
}

export function usePresortedLists<T extends Record<string, unknown>>(
  list: ComputedRef<T[] | undefined | null>,
  options: PresortOptions<T>,
) {
  const sortAlpha = (a: T, b: T, isReverse = false) => {
    const aValue = a[options.alphaKey];
    const bValue = b[options.alphaKey];
    if (!isString(aValue) && !isString(bValue)) return 0;
    if (!isString(aValue)) return isReverse ? 1 : -1;
    if (!isString(bValue)) return isReverse ? -1 : 1;
    if (aValue.toUpperCase() < bValue.toUpperCase()) {
      return isReverse ? 1 : -1;
    }
    if (aValue.toUpperCase() > bValue.toUpperCase()) {
      return isReverse ? -1 : 1;
    }
    return 0;
  };

  const sortNumeric = (a: T, b: T, isReverse = false) => {
    const aValue = a[options.numericKey];
    const bValue = b[options.numericKey];
    if (!isNumber(aValue) && !isNumber(bValue)) return 0;
    if (!isNumber(aValue)) return isReverse ? 1 : -1;
    if (!isNumber(bValue)) return isReverse ? -1 : 1;
    if (aValue < bValue) {
      return isReverse ? 1 : -1;
    }
    if (aValue > bValue) {
      return isReverse ? -1 : 1;
    }
    return 0;
  };

  const sortAlphaList = computed(() => [...(list.value ?? [])].sort((a, b) => sortAlpha(a, b)));
  const sortNumericList = computed(() => [...(list.value ?? [])].sort((a, b) => sortNumeric(a, b)));
  const sorted = reactive({
    alpha: sortAlphaList,
    alphaReverse: computed(() => sortAlphaList.value.toReversed()),
    numeric: sortNumericList,
    numericReverse: computed(() => sortNumericList.value.toReversed()),
  });

  return { sorted };
}
