/**
 * PlantApi
 * 1.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.Defaults<Oazapfts.CustomHeaders> = {
    headers: {},
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type EmpHourDto = {
    employeeNumber: number;
    hours: number;
};
export type LaborHistoryDto = {
    "number": number;
    historyDate: string;
    equipmentNumber: number;
    equipmentName: string;
    hours: number;
    isCar: boolean;
    isCsf: boolean;
    comments: string;
    inspectionNumber: number;
    zoneNumber: number;
    employeeHourList: EmpHourDto[];
};
export type DataStatus = "success" | "notValid" | "exception" | "error" | "inUse" | "notFound" | "noChange" | "inProgress" | "invalidPage" | "duplicateEntity" | "noContent";
export type ErrorResponse = {
    message: string;
    kind: DataStatus;
    exception: string | null;
    stackTrace: string | null;
    exceptionType: string;
    innerException: string | null;
    validationErrors: {
        [key: string]: string[];
    } | null;
};
export type WorkpackItemDto = {
    "type": "WO" | "DD" | "WS";
    "number": number;
    itemStatus: string;
    date: string;
    skill: string;
    day: number;
    equipNumber: number;
    equipName: string;
    employeeNumber: number;
    employeeName: string;
    selected: boolean;
    forecastMinutes: number;
    workpackCode: string;
    displayOrder: number;
    status: string;
    hours: number;
    comment: string;
    nonTagged: boolean;
    prodLine: number;
    workOrderShift: number;
    workGroupNumber: number;
    workOrderPriority: string;
    team: boolean;
    workOrderInspection: string;
    criticalEquipment: boolean;
    completedCode: string;
    workpackCreatedByEmployeeNumber: number;
    workpackCreatedByName: string;
    addWork: boolean;
    workOrderDownDayOnly: string;
    worksheetDay: number;
    workOrderAuth: boolean;
    shiftAssignment: number;
    dateClosed?: string | null;
    workpackSupervisorEmployeeNumber: number;
    workpackSupervisorName: string;
    worksheetMaterialForwarded: boolean;
    worksheetTasksDone: number;
    worksheetTasksNotDone: number;
    worksheetTasksForwarded: number;
    completedDate?: string | null;
    taskHeight: number;
};
export type WorkpackPendingRequest = {
    id: number;
    "type": string;
    equipNumber: number;
    skill: string;
    supervisorNumber: number;
    employeeNumber: number;
    wpDate: string;
    wpNumber: string;
    shift: number;
    order: number;
};
export type ValidationProblemDetails = {
    "type": string | null;
    title: string | null;
    status?: number | null;
    detail: string | null;
    instance: string | null;
    errors: {
        [key: string]: string[];
    };
    [key: string]: any;
};
export type WorkpackUnassignItemRequest = {
    id: number;
    "type": string;
    wpNumber: string | null;
    wpDate: string;
    employeeNumber: number;
};
export type WorkpackUnassignRequest = {
    wpNumber: string | null;
    wpDate: string;
    employeeNumber: number;
};
export type WorkpackIssueRequest = {
    id: number;
    wpDate: string;
    supervisorNumber: number;
    employeeNumber: number;
    shift: number;
};
export type WpItem = {
    "type": string;
    id: number;
};
export type WorkpackSort = {
    date: string;
    workpackCode: string;
    employeeNumber: number;
    wpItemList: WpItem[];
};
export type WorkpackAssignRequest = {
    id: number;
    wpNumber: string | null;
    wpDate: string;
    supervisorNumber: number;
    employeeNumber: number;
    shift: number;
};
export type WeekCloseStatus = "noCloseNecessary" | "successfullyClosed" | "inProgress" | "inWorksheetPost" | "inCarPost" | "inDailyDutyPost" | "inCallWorkPost" | "inCsbdPost" | "inRecreatingDailyDuties" | "inRefreshingCars" | "inWorksheetCreate" | "validationFailed" | "postingFailed" | "workCreateFailed" | "operationError";
export type CloseWeekProblem = {
    itemNumber: number;
    itemType: string;
    reason: string;
};
export type WorkpackWeekCloseStatusDto = {
    status: WeekCloseStatus;
    dateFor: string;
    numberOfWorkItemsToResolve: number;
    numberOfWorkItemsResolved: number;
    percentWorkResolved: number;
    numberOfWorksheets: number;
    numberOfWorksheetsUnableToClose: number;
    numberOfCars: number;
    numberOfCarsUnableToClose: number;
    numberOfDailyDuties: number;
    numberOfDailyDutiesUnableToClose: number;
    numberOfCallWork: number;
    numberOfCallWorkUnableToClose: number;
    numberOfBreakDown: number;
    numberOfBreakDownsUnableToClose: number;
    maxWorksheetsToCreate: number;
    worksheetsCreated: number;
    percentWorksheetsCreates: number;
    problems: CloseWeekProblem[];
};
export type LookUpViewCode = {
    code: string;
    name: string;
};
export type LookUpViewNumber = {
    "number": number;
    name: string;
};
export type LookUpViewDate = {
    dayNum: number;
    date: string;
};
export type WorkpackFiltersView = {
    skillLookUpList: LookUpViewCode[];
    taskHeightList: LookUpViewNumber[];
    priorityList: LookUpViewNumber[];
    workpackDateList: LookUpViewDate[];
    carShiftList: LookUpViewNumber[];
    wsDayList: LookUpViewNumber[];
};
export type WorkpackStatusViewDto = {
    "number": string;
    date: string;
    employeeNumber: number;
    employeeName: string;
    eSupervisorNumber: number;
    supervisorName: string;
    status: string;
    numItems: number;
    numItemsClosed: number;
    percentClosed: number;
    workpackItemList: WorkpackItemDto[];
};
export type Int32DwppValueTuple = object;
export function getLaborReportHistoryList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LaborHistoryDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/laborhistoryentry", {
        ...opts
    });
}
export function createLaborReportHistory(laborHistoryDto: LaborHistoryDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LaborHistoryDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/laborhistoryentry", oazapfts.json({
        ...opts,
        method: "POST",
        body: laborHistoryDto
    }));
}
export function getLaborReportHistory($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LaborHistoryDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/laborhistoryentry/${encodeURIComponent($number)}`, {
        ...opts
    });
}
export function updateLaborReportHistory($number: number, laborHistoryDto: LaborHistoryDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LaborHistoryDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/laborhistoryentry/${encodeURIComponent($number)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: laborHistoryDto
    }));
}
export function deleteLaborReportHistory($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/laborhistoryentry/${encodeURIComponent($number)}`, {
        ...opts,
        method: "DELETE"
    });
}
export function processLaborHistory(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/laborhistoryentry/process", {
        ...opts,
        method: "POST"
    });
}
/**
 * list a Pending (not yet issued WP) or Workpack
 */
export function getCurrentWorkpack({ employeeNumber, wpDate, wpNum }: {
    employeeNumber?: number;
    wpDate?: string;
    wpNum?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkpackItemDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/workpack/listone${QS.query(QS.explode({
        employeeNumber,
        wpDate,
        wpNum
    }))}`, {
        ...opts
    });
}
/**
 * Set a Single Workpack Item as Pending. Order is set as base 0.
 */
export function setWorkpackPending(workpackPendingRequest: WorkpackPendingRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkpackItemDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/workpack/setpending", oazapfts.json({
        ...opts,
        method: "PUT",
        body: workpackPendingRequest
    }));
}
/**
 * Unassign Task from Workpack or Pending List for use with 'drag to the left grid' to remove from the right.
 */
export function unassignWorkpack(workpackUnassignItemRequest: WorkpackUnassignItemRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
        data: ErrorResponse;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/workpack/manage/unassign", oazapfts.json({
        ...opts,
        method: "DELETE",
        body: workpackUnassignItemRequest
    }));
}
/**
 * Unassign ALL Tasks from Workpack or Pending List for use with a 'Remove All' button. (Date / Employee# / Wp Number or 0)
 */
export function unassignAllWorkpacks(workpackUnassignRequest: WorkpackUnassignRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
        data: ErrorResponse;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/workpack/manage/unassign/all", oazapfts.json({
        ...opts,
        method: "DELETE",
        body: workpackUnassignRequest
    }));
}
/**
 * Create New Workpack Number from Pending List (WP# 0)
 */
export function createWorkpackFromPending(workpackIssueRequest: WorkpackIssueRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkpackItemDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/workpack/manage/issuewp", oazapfts.json({
        ...opts,
        method: "POST",
        body: workpackIssueRequest
    }));
}
/**
 * Change the Supervisor for an existing Workpack or Pending List
 */
export function updateWorkpackSupervisor({ supervisorNumber, employeeNumber, wpDate, wpNum, newSupervisorNumber }: {
    supervisorNumber?: number;
    employeeNumber?: number;
    wpDate?: string;
    wpNum?: string;
    newSupervisorNumber?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkpackItemDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/workpack/manage/changesupervisor${QS.query(QS.explode({
        supervisorNumber,
        employeeNumber,
        wpDate,
        wpNum,
        newSupervisorNumber
    }))}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Change the Employee for an existing Workpack or Pending List
 */
export function updateWorkpackEmployee({ supervisorNumber, employeeNumber, wpDate, wpNum, newEmployeeNumber }: {
    supervisorNumber?: number;
    employeeNumber?: number;
    wpDate?: string;
    wpNum?: string;
    newEmployeeNumber?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkpackItemDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/workpack/manage/changeemployee${QS.query(QS.explode({
        supervisorNumber,
        employeeNumber,
        wpDate,
        wpNum,
        newEmployeeNumber
    }))}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Change the Workpack Date for an existing Workpack or Pending List
 */
export function updateWorkpackDate({ supervisorNumber, employeeNumber, wpDate, wpNum, newWpDate }: {
    supervisorNumber?: number;
    employeeNumber?: number;
    wpDate?: string;
    wpNum?: string;
    newWpDate?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkpackItemDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/workpack/manage/changedate${QS.query(QS.explode({
        supervisorNumber,
        employeeNumber,
        wpDate,
        wpNum,
        newWpDate
    }))}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Change the Displayed Sort Order for the Tasks for an existing Workpack or Pending List
 */
export function updateWorkpackSort(workpackSort: WorkpackSort, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkpackItemDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/workpack/manage/changesort", oazapfts.json({
        ...opts,
        method: "POST",
        body: workpackSort
    }));
}
/**
 * Check and auto-assign for the Date (day-of-week) / Employee's Worksheets for the specified Pending or Workpack.
 */
export function autoAssignWorkpack(workpackAssignRequest: WorkpackAssignRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkpackItemDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/workpack/manage/autoassign", oazapfts.json({
        ...opts,
        method: "POST",
        body: workpackAssignRequest
    }));
}
/**
 * Close and Archive a Single Workpack (Not Part of Week Close). Returns untouched work to Assignables.
 */
export function closeWorkpackAndArchive(wpNum: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkpackItemDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/workpack/closeandarchive/${encodeURIComponent(wpNum)}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Close the Workpack Week.  Use closeweek/check to determine status.
 */
export function processWorkpackCloseWeek({ autoCreate }: {
    autoCreate?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 202;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/workpack/closeweek${QS.query(QS.explode({
        autoCreate
    }))}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Check for the status of a week close operation.
 */
export function checkWorkpackCloseStatus(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkpackWeekCloseStatusDto;
    } | {
        status: 202;
        data: WorkpackWeekCloseStatusDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/workpack/closeweek/status", {
        ...opts
    });
}
/**
 * List of Current Weeks Workpack Numbers. Pending '0' are not included.
 */
export function getWorkpackNumberList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: string[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/workpack/listnumbers", {
        ...opts
    });
}
/**
 * All the Workpack Filter Lookups
 */
export function getWorkpackFilterLookupList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkpackFiltersView;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/workpack/tasks/filters", {
        ...opts
    });
}
/**
 * All Workpack Items Unassigned, Assigned, In process, and Pending. READ THE NOTES!!
 */
export function getWorkpackList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkpackItemDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/workpack/tasks/listall", {
        ...opts
    });
}
/**
 * list Workpack numbers for a supervisor, employee, date
 */
export function getTheSupervisorEmployeeDateList({ supervisorNumber, employeeNumber, wpDate }: {
    supervisorNumber?: number;
    employeeNumber?: number;
    wpDate?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: string[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/workpack/listsupervisoremployeedatelist${QS.query(QS.explode({
        supervisorNumber,
        employeeNumber,
        wpDate
    }))}`, {
        ...opts
    });
}
/**
 * All Workpack Items Unassigned, Assigned, In process, and Pending. READ THE NOTES!!
 */
export function getOpenWorkpackList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WorkpackStatusViewDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/workpack/openworkpacklist", {
        ...opts
    });
}
/**
 * Close Workwheet
 */
export function processWorksheetClose($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Int32DwppValueTuple;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/worksheetclose/${encodeURIComponent($number)}`, {
        ...opts,
        method: "POST"
    });
}
