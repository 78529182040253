import type BaseAuthClass from "~/auth/-base-auth-class.ts";
import type MsalAuthClass from "~/auth/providers/msal-browser.ts";
import type { RuntimeConfig } from "nuxt/schema";

export const AUTH_PROVIDER_STORAGE_KEY = "Auth:Provider";

export type IAuthProvider<T extends BaseAuthClass> = { new (config: RuntimeConfig): T };
export type PROVIDER_NAMES = "msal-browser" | "test";

export type Providers = {
  [K in PROVIDER_NAMES]?: {
    validate: (config: RuntimeConfig) => boolean;
    import: () => Promise<IAuthProvider<BaseAuthClass>>;
  };
};

export const PROVIDERS: Providers = {
  "msal-browser": {
    validate: (config: RuntimeConfig) => {
      return ["AZURE_AD_CLIENT_ID", "AZURE_AD_TENANT_ID"].every((key) => key in config.public);
    },
    import: async () => (await import("./providers/msal-browser.ts")).default as IAuthProvider<MsalAuthClass>,
  },
};

export const PROVIDER_KEYS = Object.keys(PROVIDERS) as PROVIDER_NAMES[];
