import { useStorage, StorageSerializers } from "@vueuse/core";
import { useCompanyInfoStore } from "~/store/company-info";
import { useConnectStatusStore } from "~/store/connect-status";
import { useEquipmentLookupListStore } from "~/store/lookup-lists.ts";
import { useGlobalNotification } from "~/store/global-notification.ts";
import { getUserPlantList, type PlantInfo, getUser } from "~/connectables/fetch/plantApi.ts";
import { useLocalFetch } from "~/composables/apiHandler.ts";

declare module "#app" {
  interface RuntimeNuxtHooks {
    "ckClient:plant-change": (plant: PlantInfo | null) => Promise<void> | void;
  }
}

/**
 * This will return handlers for specifying the current plant. This value is used by our api handlers to set the
 * `Nexcor-Plant` header.
 */
export const useCurrentPlant = defineStore("currentPlant", () => {
  const { callHook } = useNuxtApp();

  const currentPlant = useStorage<PlantInfo | null>("KLEANZ_PLANT", null, localStorage, {
    serializer: StorageSerializers.object,
  });

  const setPlant = (plant: PlantInfo | null) => {
    if (plant !== null) {
      currentPlant.value = plant;
      callHook("ckClient:plant-change", currentPlant.value).then(DO_NOTHING);

      const globalNotification = useGlobalNotification();
      globalNotification.showSuccess("home.welcomeBack");
    }
    return currentPlant.value;
  };

  const clearPlant = () => {
    currentPlant.value = null;
    callHook("ckClient:plant-change", currentPlant.value).then(DO_NOTHING);
  };

  // Ensure no one changes the value of the current plant directly.
  // Use an empty object as a fallback because `readonly` gets angry at null.
  const data = readonly(currentPlant);

  return {
    data,
    setPlant,
    clearPlant,
  };
});

export const usePlantListStore = defineStore("PlantListStore", () => {
  const currentPlant = useCurrentPlant();
  const plantUser = usePlantUserStore();
  const resetPlant = useResetPlantData();
  const { fetch, data, resolved, ...theRest } = useLocalFetch("getUserPlantlist", getUserPlantList);
  const { $auth } = useNuxtApp();

  const check = async () => {
    const isAuthed = await $auth.isAuthed();
    if (!isAuthed) {
      resetPlant.reset();
      return false;
    }
    await fetch();
    if (resolved.value) {
      // If data has the current plant
      if (Array.isArray(data.value) && data.value.some((value) => value.plantId === currentPlant.data?.plantId)) {
        await plantUser.fetch();
        return true;
      }
    }
    resetPlant.reset();
    return false;
  };
  return {
    check,
    data,
    resolved,
    fetch,
    ...theRest,
  };
});

export const usePlantUserStore = defineStore("PlantUserStore", () => {
  const plantUser = useLocalFetch("getUser", getUser);
  const isReady = useIsReady(plantUser);
  return {
    isReady,
    ...plantUser,
  };
});

export const useResetPlantData = () => {
  const companyInfoStore = useCompanyInfoStore();
  const connectStatusStore = useConnectStatusStore();
  const currentPlant = useCurrentPlant();
  const plantUser = usePlantUserStore();
  const EquipmentLookupListStore = useEquipmentLookupListStore();
  const reset = () => {
    companyInfoStore.reset();
    connectStatusStore.reset();
    currentPlant.clearPlant();
    plantUser.reset();
    EquipmentLookupListStore.voidCache();
    EquipmentLookupListStore.reset();
  };
  return {
    reset,
  };
};
