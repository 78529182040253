import revive_payload_client_4sVQNw7RlN from "/src/ckClient/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/src/ckClient/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/src/ckClient/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/src/ckClient/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/src/ckClient/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/src/ckClient/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/src/ckClient/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/src/ckClient/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/src/ckClient/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/src/ckClient/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tMGwffvjBc from "/src/ckClient/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_client_RZVNejKJBe from "/src/ckClient/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/src/ckClient/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/src/ckClient/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_WLsn00x1qh from "/src/ckClient/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import _00_settings_GciA19Dq4m from "/src/ckClient/plugins/00_settings.ts";
import _01_sentry_client_2GCcmGPBnn from "/src/ckClient/plugins/01_sentry.client.ts";
import _02_errorHandler_XC8qa9n4R6 from "/src/ckClient/plugins/02_errorHandler.ts";
import _03_auth_69D7hon8zm from "/src/ckClient/plugins/03_auth.ts";
import _10_navbarConfig_je5Hmoyllp from "/src/ckClient/plugins/10_navbarConfig.ts";
import _20_clickOutside_To2zAlRc5h from "/src/ckClient/plugins/20_clickOutside.ts";
import _22_mouseCoords_VNjTMpDXgY from "/src/ckClient/plugins/22_mouseCoords.ts";
import _23_sortable_client_q6gXQfUNdg from "/src/ckClient/plugins/23_sortable.client.js";
import _24_zod_i18n_client_efLqZQZuRW from "/src/ckClient/plugins/24_zod-i18n.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tMGwffvjBc,
  plugin_client_RZVNejKJBe,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_WLsn00x1qh,
  _00_settings_GciA19Dq4m,
  _01_sentry_client_2GCcmGPBnn,
  _02_errorHandler_XC8qa9n4R6,
  _03_auth_69D7hon8zm,
  _10_navbarConfig_je5Hmoyllp,
  _20_clickOutside_To2zAlRc5h,
  _22_mouseCoords_VNjTMpDXgY,
  _23_sortable_client_q6gXQfUNdg,
  _24_zod_i18n_client_efLqZQZuRW
]