import type { PossibleErrorResponses } from "~/utils/types.ts";
import type { REJECTED_STATUS } from "~/composables/apiHandler.ts";

export class LocalFetchError<T_ErrorType extends PossibleErrorResponses> extends Error {
  constructor(
    public type: "json" | "text" | "error",
    public body: T_ErrorType,
    public status: REJECTED_STATUS,
    cause?: Error,
  ) {
    super(JSON.stringify(body), { cause });
  }
}
