<script lang="ts" setup>
const props = defineProps({
  throttle: {
    type: Number,
    default: 200,
  },
  duration: {
    type: Number,
    default: 2000,
  },
  height: {
    type: Number,
    default: 3,
  },
  color: {
    type: [String, Boolean],
    default:
      "repeating-linear-gradient(to right, var(--color-nred-3) 0%, var(--color-nyellow-4) 50%, var(--color-nblue-2) 100%)",
  },
});
const { progress, isLoading, start, finish, clear } = useLoadingIndicator({
  duration: props.duration,
  throttle: props.throttle,
});
defineExpose({ progress, isLoading, start, finish, clear });
</script>
<template>
  <div :hidden="!isLoading" class="global-loading-overlay">
    <LoadingSpinner />
  </div>
</template>
<style scoped lang="postcss">
.global-loading-overlay:not([hidden]) {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  display: grid;
  place-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  transform: none;
  z-index: 10;
}
</style>
